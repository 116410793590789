import React from 'react'
import { Button, Row, Col, Modal } from 'antd'
import { useTimetracking } from '../../../hooks/useTimetracking'
import WorktimePending from './WorktimePending'
import styled from 'styled-components'
import { humanizeTime } from '../../../lib/dateHelpers'

const StyledButton = styled(Button)`
  overflow: hidden;
`

const Container = styled.div`
  padding: 10px 10px 0 10px;
`

const WorktimeBar = (props) => {
  const {
    handleTrackTime,
    timeStarted,
    setTimeStarted,
    showShortBreakWarning,
    setShowShortBreakWarning,
    lastBreakDuration,
    requiredBreakTime,
    startEventIsProcessing,
    setStartEventIsProcessing,
    stopEventIsProcessing,
    setStopEventIsProcessing,
    breakTimeSeconds
  } = useTimetracking()

  const warningConfirmTitle = (
    <>
      <p>You haven't reached your minimum Breaktime yet.</p>
      <p>Required Breaktime {requiredBreakTime}, your breaktime {lastBreakDuration}</p>
    </>
  )

  const handleOnClick = (event, trigger) => {
    const triggerIsStart = trigger === 'Start'
    setStartEventIsProcessing(triggerIsStart)
    setStopEventIsProcessing(!triggerIsStart)
    handleTrackTime(trigger)
  }

  const handleWarningConfirm = () => {
    setShowShortBreakWarning(false)
    handleTrackTime('Start', true)
  }

  const handleCancelWarning = () => {
    setShowShortBreakWarning(false)
    setTimeStarted(false)
    setStartEventIsProcessing(false)
  }

  return (
    <Container {...props}>
      <Row justify='center' align='top'>
        <Col span={4}>
          <StyledButton
            type='primary'
            size='large'
            onClick={(event) => handleOnClick(event, 'Start')}
            disabled={timeStarted}
            loading={startEventIsProcessing}
            block
            id='timelog-start-button'
          >
            {!startEventIsProcessing && <>Start</>}
          </StyledButton>
          <Modal
            title='Breaktime Warning'
            visible={showShortBreakWarning}
            onOk={handleWarningConfirm}
            onCancel={handleCancelWarning}
          >
            {warningConfirmTitle}
          </Modal>
        </Col>
        <Col span={16}>
          <WorktimePending />
        </Col>
        <Col span={4}>
          <StyledButton
            type='danger'
            size='large'
            onClick={(event) => handleOnClick(event, 'Stop')}
            disabled={!timeStarted}
            loading={stopEventIsProcessing}
            block
            id='timelog-stop-button'
          >
            {!stopEventIsProcessing && <>Stop</>}
          </StyledButton>
        </Col>
      </Row>
      {!timeStarted && (
        <Row justify='center' align='middle'>
          Time since last stop:
          {' '}
          {humanizeTime(breakTimeSeconds)}
        </Row>
      )}
    </Container>
  )
}

export default WorktimeBar
