import { Button, PageHeader as AntPageHeader } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { useAdmin } from '../../hooks/useAdmin'
import { useTimelog } from '../../hooks/useTimelog'

const StyledPageHeader = styled(AntPageHeader)`
  background-color: #3f51b5;
  width: 100%;
  user-select: none;
  && .ant-page-header-heading-title {
    color: white;
  }
  && .ant-breadcrumb > ol > li > span > * {
    cursor: default;
    color: #fbfbfb;
  }
`

const PageHeader = (props) => {
  const {
    triggerReloadAdmin,
    handleLogoutFlightplan,
    isAdminPageViewed,
    selectedMenuPoint,
    breadcrumbRoute
  } = useAdmin()
  const { triggerReloadTimelog, handleLogoutTimelog } = useTimelog()

  const triggerReloadSection = isAdminPageViewed ? triggerReloadAdmin : triggerReloadTimelog

  const fullLogout = () => {
    handleLogoutTimelog()
    handleLogoutFlightplan()
  }

  const showRefreshButton = selectedMenuPoint !== 'billing'
  return (
    <StyledPageHeader
      title='Flightplan v2'
      extra={[
        ...(showRefreshButton
          ? [
            <Button key='refresh-button' type='primary' onClick={() => triggerReloadSection()}>
              REFRESH DATA
            </Button>
            ]
          : []),
        <Button key='logout-button' type='primary' onClick={() => fullLogout()}>
          LOG OUT
        </Button>
      ]}
      breadcrumb={{
        routes: breadcrumbRoute
      }}
      {...props}
    />
  )
}

export default PageHeader
