import React, { useState, useEffect } from 'react'
import { Heatmap, G2 } from '@ant-design/plots'
import { useTimelog } from '../../../hooks/useTimelog'
import { getMonthLabel, secondsToHourFractionString } from '../../../lib/dateHelpers'
import { scrollToDay } from '../../../lib/util'
import styled from 'styled-components'

const StyledHeatmap = styled(Heatmap)`
  padding: 5px;
`

const CalendarHeatMap = (props) => {
  const [data, setData] = useState([])
  const {
    calendarChartData,
    setHighlightedDay
  } = useTimelog()

  useEffect(() => {
    setData(calendarChartData)
  }, [calendarChartData])

  G2.registerShape('polygon', 'boundary-polygon', {
    draw (cfg, container) {
      const group = container.addGroup()
      const attrs = {
        fill: cfg.color,
        path: []
      }
      const points = cfg.points
      const path = [
        ['M', points[0].x, points[0].y],
        ['L', points[1].x, points[1].y],
        ['L', points[2].x, points[2].y],
        ['L', points[3].x, points[3].y],
        ['Z']
      ]

      attrs.path = this.parsePath(path)
      group.addShape('path', {
        attrs
      })

      if (cfg.data.lastWeek && cfg.data.monthNumber !== 11) {
        const whiteOutlineLastWeek = [
          ['M', points[2].x, points[2].y],
          ['L', points[1].x, points[1].y],
          ['L', points[0].x, points[0].y],
          ['L', points[3].x, points[3].y]
        ]

        const whiteOutlineLastDay = [
          ['M', points[1].x, points[1].y],
          ['L', points[0].x, points[0].y],
          ['L', points[3].x, points[3].y]
        ]

        const linePathLastWeek = [
          ['M', points[2].x, points[2].y],
          ['L', points[3].x, points[3].y]
        ]

        const linePathLastDay = [
          ['M', points[1].x, points[1].y],
          ['L', points[2].x, points[2].y],
          ['L', points[3].x, points[3].y]
        ]

        let partialOutline = whiteOutlineLastWeek
        let linePath = linePathLastWeek
        if (cfg.data.lastDay && cfg.data.weekDayNumber !== 6) {
          partialOutline = whiteOutlineLastDay
          linePath = linePathLastDay
        }
        group.addShape('path', {
          attrs: {
            path: this.parsePath(partialOutline),
            stroke: '#fff',
            lineWidth: 2
          }
        })
        group.addShape('path', {
          attrs: {
            path: this.parsePath(linePath),
            lineWidth: 2,
            stroke: '#404040'
          }
        })
        return group
      }

      if (cfg.data.firstWeek && cfg.data.monthNumber !== 0) {
        const whiteOutlineFirstWeek = [
          ['M', points[0].x, points[0].y],
          ['L', points[3].x, points[3].y],
          ['L', points[2].x, points[2].y],
          ['L', points[1].x, points[1].y]
        ]

        const whiteOutlineFirstDay = [
          ['M', points[1].x, points[1].y],
          ['L', points[2].x, points[2].y],
          ['L', points[3].x, points[3].y]
        ]

        const linePathFirstWeek = [
          ['M', points[0].x, points[0].y],
          ['L', points[1].x, points[1].y]
        ]

        const linePathFirstDay = [
          ['M', points[1].x, points[1].y],
          ['L', points[0].x, points[0].y],
          ['L', points[3].x, points[3].y]
        ]

        let partialOutline = whiteOutlineFirstWeek
        let linePath = linePathFirstWeek
        if (cfg.data.firstDay && cfg.data.weekDayNumber !== 0) {
          partialOutline = whiteOutlineFirstDay
          linePath = linePathFirstDay
        }
        group.addShape('path', {
          attrs: {
            path: this.parsePath(partialOutline),
            stroke: '#fff',
            lineWidth: 2
          }
        })
        group.addShape('path', {
          attrs: {
            path: this.parsePath(linePath),
            lineWidth: 2,
            stroke: '#404040'
          }
        })
        return group
      }

      group.addShape('path', {
        attrs: {
          path: this.parsePath(path),
          stroke: '#fff',
          lineWidth: 2
        }
      })
      return group
    }
  })

  const config = {
    data,
    height: 300,
    autoFit: true,
    xField: 'weekNumber',
    yField: 'weekDayNumber',
    reflect: 'y',
    shape: 'boundary-polygon',
    type: 'polygon',
    colorField: 'color',
    color: ({ color }) => (color),
    meta: {
      weekDayNumber: {
        type: 'cat',
        values: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
      },
      weekNumber: {
        type: 'cat'
      },
      totalDayTimeSpent: {
        sync: true
      },
      isoDate: {
        type: 'cat'
      },
      tooltipTitle: {
        type: 'cat'
      },
      spendingReason: {
        type: 'cat'
      }
    },
    yAxis: {
      grid: null
    },
    tooltip: {
      title: 'tooltipTitle',
      fields: ['totalDayTimeSpent', 'spendingReason'],
      formatter: ({ totalDayTimeSpent, spendingReason }) => {
        return {
          name: spendingReason,
          value: secondsToHourFractionString(totalDayTimeSpent)
        }
      }
    },
    xAxis: {
      position: 'top',
      tickLine: null,
      line: null,
      label: {
        offset: 3,
        style: {
          fontSize: 10,
          textBaseline: 'top'
        },
        formatter: (value) => {
          return getMonthLabel(value)
        }
      }
    },
    onReady: (plot) => {
      plot.on(
        'element:click',
        ({ data: { data: { isoDate } } }) => {
          setHighlightedDay(isoDate)
          scrollToDay(isoDate)
        }
      )
    }
  }

  return (
    <StyledHeatmap {...config} {...props} />
  )
}

export default CalendarHeatMap
