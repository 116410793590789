import { createContext, useContext } from 'react'
import { useLocalStorage } from './useLocalStorage'
import PropTypes from 'prop-types'

export const UserPreferencesContext = createContext()

export const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext)

  if (context === undefined) {
    throw new Error('useUserPreferences must be used within an UserPreferencesProvider')
  }

  return context
}

export const UserPreferencesProvider = ({ children }) => {
  const [preferredChart, setPreferredChart] = useLocalStorage('Timelog_Last_Selected_Diagram', 'BAR_CHART')
  const [isSidebarCollpased, setIsSidebarCollpased] = useLocalStorage('Flightplan_Sidebar_Collapsed', false)

  return (
    <UserPreferencesContext.Provider
      value={{
        preferredChart,
        setPreferredChart,
        isSidebarCollpased,
        setIsSidebarCollpased
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  )
}

UserPreferencesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
