import { startOfToday, endOfToday, startOfYear, endOfYesterday, isBefore, isAfter, isSameDay, startOfDay, endOfDay, isToday } from 'date-fns'
import { getWorkingDays, filterWorkedTimerEvents } from '../../lib/dateHelpers'
import { getWorkedSecondsFromEvents, getUTCData } from '../../hooks/useTimetracking'
import { getRequiredBalanceToday } from '../../lib/formatChartData'

const currentYear = new Date().getFullYear()

export const sortFloat = (propName) => (a, b) => (parseFloat(a[propName]) - parseFloat(b[propName]))

export const sortByUserName = (a, b) => (a.user.name.localeCompare(b.user.name))

export const getTrackedSeconds = (usersTrackedTimes, username, currentDay) => {
  const userTrackedTimes = usersTrackedTimes[username]
  return userTrackedTimes
    ? userTrackedTimes.reduce((acc, { timeSpent, spentAt, ...rest }) => {
      const spentAtDate = new Date(spentAt)
      if (!currentDay || isSameDay(spentAtDate, currentDay)) {
        return acc + timeSpent
      }
      return acc
    }, 0)
    : 0
}

export const getHoursForCurrentYear = (purposeTimes, purposeSelector, untilNow) => {
  const times = purposeTimes.find(purposeTime => purposeTime.purpose === purposeSelector)
  if (!times) {
    return 0
  }
  const entries = times.body.userData
  let filteredEntries = entries.filter(entry => entry.startDate.startsWith(currentYear))
  if (untilNow) {
    filteredEntries = filteredEntries.filter(entry => isBefore(new Date(entry.startDate), new Date()))
  }
  const hoursForCurrentYear = filteredEntries.reduce((acc, curr) => {
    return acc + curr.hours
  }, 0)
  return hoursForCurrentYear
}

export const getUserDailyAverageHours = (purposeTimes) => {
  const contracts = purposeTimes.find(purposeTime => purposeTime.purpose === 'contracts')
  const contractEntries = contracts.body.userData
  const contractEntryForCurrentYear = contractEntries.find(contractEntry => contractEntry.startDate.startsWith(currentYear))
  const userDailyAverageHours = contractEntryForCurrentYear.hours / (getWorkingDays(contractEntryForCurrentYear).length - 1) // hardcoded fix as 2024 has 1/2 working days on new year and xmas
  return userDailyAverageHours
}

export const getUserWorkingState = (purposeTimes) => {
  const times = purposeTimes.find(purposeTime => purposeTime.purpose === 'timetracking')
  const timesEntries = times.body.userData
  return timesEntries.length ? (timesEntries[timesEntries.length - 1].event === 'Start') : false
}

export const getTimedSeconds = (purposeTimes, { dateRangeStart, dateRangeEnd }) => {
  const workedTimes = purposeTimes.find(purposeTime => purposeTime.purpose === 'timetracking')
  const workedTimesEntries = workedTimes.body.userData
  const workTimerEventsToday = filterWorkedTimerEvents(
    workedTimesEntries,
    { dateRangeStart, dateRangeEnd }
  )
  // Add Stop Event for working users to calculate currently running time
  const userIsCurrentlyWorking = getUserWorkingState(purposeTimes)
  if (userIsCurrentlyWorking) {
    const endOfWorkDate = isToday(dateRangeStart) ? new Date() : endOfDay(dateRangeEnd)
    const [utcTimestamp] = getUTCData(endOfWorkDate)
    workTimerEventsToday.push({ eventType: 'Stop', utc: utcTimestamp })
  }
  const timedSecondsToday = getWorkedSecondsFromEvents(workTimerEventsToday)
  return timedSecondsToday
}

export const getHoursBeforeToday = (purpose) => (purposeTimes) => {
  const times = purposeTimes.find(purposeTime => purposeTime.purpose === purpose)
  if (!times) {
    return 0
  }
  const entries = times.body.userData
  const entriesBeforeToday = entries.filter(entry => isBefore(new Date(entry.startDate), startOfToday()) && isAfter(new Date(entry.startDate), startOfYear(new Date())))

  const hoursBeforeToday = entriesBeforeToday.reduce((acc, curr) => {
    if (isBefore(new Date(curr.endDate), startOfToday())) {
      return acc + curr.hours
    }
    const workingDays = getWorkingDays(curr).length
    const workingDaysBeforeToday = getWorkingDays({ startDate: new Date(curr.startDate), endDate: endOfYesterday() }).length
    return acc + (workingDaysBeforeToday / workingDays * curr.hours)
  }, 0)
  return hoursBeforeToday
}

export const getNonWorkHoursBeforeToday = (purposeTimes) => {
  const sickHours = getHoursBeforeToday('sicks')(purposeTimes)
  const vacationHours = getHoursBeforeToday('vacations')(purposeTimes)
  const educationHours = getHoursBeforeToday('educations')(purposeTimes)

  return sickHours + vacationHours + educationHours
}

export const getCurrentContractBalance = (purposeTimes) => {
  const secondsInOneHour = 60 * 60
  const workedTimes = purposeTimes.find(purposeTime => purposeTime.purpose === 'timetracking')
  const workedTimesEntries = workedTimes.body.userData // those are limited to the current year
  const workTimerEventsForCurrentYear = filterWorkedTimerEvents(
    workedTimesEntries,
    { dateRangeStart: startOfYear(new Date()), dateRangeEnd: endOfYesterday() }
  )
  const trackedSecondsTillToday = getWorkedSecondsFromEvents(workTimerEventsForCurrentYear)

  const contracts = purposeTimes.find(purposeTime => purposeTime.purpose === 'contracts')
  const contractEntries = contracts.body.userData
  const contractEntryForCurrentYear = contractEntries.find(contractEntry => contractEntry.startDate.startsWith(currentYear))

  const dailyRequiredAverageSeconds = getUserDailyAverageHours(purposeTimes) * secondsInOneHour
  const currentlyRequiredSeconds = getRequiredBalanceToday(dailyRequiredAverageSeconds, contractEntryForCurrentYear)

  const nonWorkSecondsTaken = getNonWorkHoursBeforeToday(purposeTimes) * secondsInOneHour

  return (nonWorkSecondsTaken + trackedSecondsTillToday) - currentlyRequiredSeconds
}

export const formatTableData = (usersTimes, usersTrackedTimes, controlDate) => {
  if (!controlDate) {
    return []
  }
  return usersTimes
    .filter(user => user.username !== 'fd')
    .map(user => {
      const vacationHoursTaken = getHoursForCurrentYear(user.purposeTimes, 'vacations')
      const sickLeaveHoursTaken = getHoursForCurrentYear(user.purposeTimes, 'sicks')
      const contractTotalHours = getHoursForCurrentYear(user.purposeTimes, 'contracts')
      const userDailyAverageHours = getUserDailyAverageHours(user.purposeTimes)
      const vacationDaysTaken = vacationHoursTaken / userDailyAverageHours
      const sickLeaveDaysTaken = sickLeaveHoursTaken / userDailyAverageHours

      const isWorking = getUserWorkingState(user.purposeTimes)
      const timedSecondsToday = getTimedSeconds(user.purposeTimes, { dateRangeStart: startOfToday(), dateRangeEnd: endOfToday() })
      const previousDayRange = { dateRangeStart: startOfDay(controlDate), dateRangeEnd: endOfDay(controlDate) }
      const previousTimedSeconds = getTimedSeconds(user.purposeTimes, previousDayRange)
      const previousTrackedSeconds = getTrackedSeconds(usersTrackedTimes, user.username, startOfDay(controlDate))

      const contractSecondsBalance = getCurrentContractBalance(user.purposeTimes)

      return {
        key: user.id,
        user: {
          id: user.id,
          name: user.name,
          username: user.username
        },
        userDailyAverageHours,
        isWorking,
        timedSecondsToday,
        contractSecondsBalance,
        vacationDaysTaken: (vacationDaysTaken % 1 === 0) ? vacationDaysTaken : vacationDaysTaken.toFixed(2),
        sickLeaveDaysTaken: (sickLeaveDaysTaken % 1 === 0) ? sickLeaveDaysTaken : sickLeaveDaysTaken.toFixed(2),
        contractTotalHours,
        previousTimedSeconds,
        previousTrackedSeconds
      }
    })
    .sort(sortByUserName)
}
