import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AuthContext } from '../oauth/AuthContext'
import { startOfToday, subDays, endOfToday } from 'date-fns'
import { formatTrackedTimes, getQueryProjectTitles, userTrackedTimesQuery } from '../lib/formatTrackedTimes'
import { getBarChartData, getCalendarData, getPendingLoggedChartData, getTableChartData } from '../lib/formatChartData'
import { getUserBookedTimes, getUserContract, getCurrentUser } from '../lib/userContractData'
import { filterTrackedDays } from '../lib/dateHelpers'
import { formatBookedTimes } from '../lib/formatBookedTimes'
import { useLocalStorage } from './useLocalStorage'
import { displayError } from '../lib/util'
import { useAdmin, MENU_POINTS } from './useAdmin'

export const TimelogContext = createContext()

export const useTimelog = () => {
  const context = useContext(TimelogContext)

  if (context === undefined) {
    throw new Error('useTimelog must be used within an TimelogProvider')
  }

  return context
}

export function TimelogProvider ({ children }) {
  const { requestGraphQlData, token, error: oAuthError } = useContext(AuthContext)
  const [cachedTrackedTimes, setCachedTrackedTimes] = useLocalStorage('Timelog_Tracked_Times', '[]')
  const [cachedPageCursor, setCachedPageCursor] = useLocalStorage('Timelog_Last_Page_Cursor', '')
  const [trackedTimesAll, setTrackedTimesAll] = useState({})
  const [highlightedDay, setHighlightedDay] = useState(null)
  const [isLoadingBarChart, setIsLoadingBarChart] = useState(false)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [currentBalanceData, setCurrentBalanceData] = useState({ // eslint-disable-line no-unused-vars
    balanceTitle: '',
    chartBalance: 0.5,
    thresholdHours: {
      red: 80,
      yellow: 40
    }
  })
  const [tableChartData, setTableChartData] = useState([])
  const [pendingLoggedData, setPendingLoggedData] = useState({
    secondsTrackedToday: 0,
    requiredSecondsToday: 28800 // 8 hours
  })
  const [calendarChartData, setCalendarChartData] = useState([])
  const [barChartData, setBarChartData] = useState([])
  const [selectedRange, setSelectedRange] = useState({
    start: subDays(startOfToday(), 10),
    end: endOfToday()
  })
  const [isStillLoading, setIsStillLoading] = useState(true)
  const [contract, setContract] = useState({
    startDate: null,
    endDate: null,
    businessDays: 0,
    requiredHours: 0,
    requiredDailyAverageHours: 0,
    thresholdHours: null
  })
  const [bookedTimes, setBookedTimes] = useState({
    educations: [],
    sicks: [],
    vacations: []
  })
  const [reloadData, setReloadData] = useState(0)

  const getProjectTitle = async (ids, signal) => {
    try {
      const query = getQueryProjectTitles(ids)
      const response = await requestGraphQlData(query, signal)
      return response.data.projects.nodes
    } catch (error) {
      if (error instanceof DOMException && signal.aborted === true) {
        return
      }
      const errorMessage = `getProjectTitle failed, ${error?.message}`
      console.error(errorMessage)
      displayError(errorMessage)
    }
  }

  const getTimesWithProjectTitle = async (times, signal) => {
    const projectIds = times.reduce((acc, timeEntry) => {
      if (!timeEntry?.issue?.projectId) {
        return acc
      }
      const projectFullIdWithQuotation = `"gid://gitlab/Project/${timeEntry.issue.projectId}"`
      if (acc.find((entry) => entry === projectFullIdWithQuotation)) {
        return acc
      }
      acc.push(projectFullIdWithQuotation)
      return acc
    }, [])
    const projectTitles = await getProjectTitle(projectIds, signal)
    const timesWithProjectTitle = times.map((timeEntry) => {
      if (!timeEntry?.issue?.projectId || !projectTitles) {
        return timeEntry
      }
      const projectFullId = `gid://gitlab/Project/${timeEntry.issue.projectId}`
      const foundProjectTitle = projectTitles.find((title) => `${title.id}` === projectFullId)
      if (!foundProjectTitle) {
        return timeEntry
      }
      timeEntry.issue = { ...timeEntry.issue, projectTitle: foundProjectTitle.name }
      return timeEntry
    })
    return timesWithProjectTitle
  }

  const {
    selectedMenuPoint
  } = useAdmin()

  const getTimeLogsForUser = useCallback(async (signal, userContract, userBookedTimes) => {
    let cursor = cachedPageCursor
    const newTrackedTimes = []
    let isMoreDataAvailable = true
    try {
      while (isMoreDataAvailable) {
        const currentUser = await getCurrentUser(requestGraphQlData)
        const trackedTimesForCurrentContractPeriodQuery = userTrackedTimesQuery(currentUser.username, cursor, userContract)
        const response = await requestGraphQlData(trackedTimesForCurrentContractPeriodQuery, signal)
        const responseData = response.data.timelogs
        cursor = responseData.pageInfo.endCursor
        isMoreDataAvailable = responseData.pageInfo.hasNextPage
        const entries = responseData.nodes
        newTrackedTimes.push(...entries)
      }
    } catch (error) {
      if (error instanceof DOMException && signal.aborted === true) {
        return
      }
      const errorMessage = `getTimeLogsForUser failed:, ${error?.message}`
      console.error(errorMessage)
      displayError(errorMessage)
    }
    const parsedCachedTrackedTimes = JSON.parse(cachedTrackedTimes)
    const mergedTrackedTimes = [...newTrackedTimes, ...parsedCachedTrackedTimes]
    const mergedTimesWithProjectTitle = await getTimesWithProjectTitle(mergedTrackedTimes, signal)
    const contractTrackedTimes = formatBookedTimes(userContract, userBookedTimes)
    const allTimes = formatTrackedTimes(contractTrackedTimes, mergedTimesWithProjectTitle)
    setTrackedTimesAll(allTimes)
    // if (cursor) {
    //   setCachedTrackedTimes(JSON.stringify(mergedTrackedTimes))
    //   setCachedPageCursor(cursor)
    // }
    setIsStillLoading(false)
  }, [cachedPageCursor, token])  // eslint-disable-line

  useEffect(() => {
    setIsStillLoading(true)
    setIsLoadingBarChart(true)
    setIsLoadingTable(true)
    // load data only for some pages
    if (![MENU_POINTS.timelogs, MENU_POINTS.stats].includes(selectedMenuPoint)) {
      return
    }
    setTrackedTimesAll({})
    const controller = new AbortController()
    const { signal } = controller
    const fetchContractData = async () => {
      const [contracts, educations, sicks, vacations] = await getUserBookedTimes(requestGraphQlData, token, displayError)
      const formattedContract = getUserContract(contracts)
      await getTimeLogsForUser(signal, formattedContract, { educations, sicks, vacations })
      setContract(formattedContract)
      setBookedTimes({ educations, sicks, vacations })
    }
    if (token) {
      fetchContractData()
    }

    return () => {
      controller.abort()
    }
  }, [token, requestGraphQlData, reloadData, selectedMenuPoint]) // eslint-disable-line

  useEffect(() => {
    if (isStillLoading) {
      return
    }
    const timesToday = filterTrackedDays(
      trackedTimesAll,
      { dateRangeStart: startOfToday() }
    )
    const timesSelectedDateRange = filterTrackedDays(
      trackedTimesAll,
      { dateRangeStart: selectedRange.start, dateRangeEnd: selectedRange.end }
    )

    const pendingLoggedChartData = getPendingLoggedChartData(timesToday, contract)
    setPendingLoggedData(pendingLoggedChartData)

    const barData = getBarChartData(
      { start: selectedRange.start, end: selectedRange.end },
      timesSelectedDateRange
    )
    setBarChartData(barData)

    /*
    Disabled for now, because it is not used in favor of
    current balance based on START/STOP worked time events

    const timesBeforeToday = filterTrackedDays(
      trackedTimesAll,
      { dateRangeStart: parseISO(contract.startDate), dateRangeEnd: endOfYesterday() }
    )
    const currentBalanceData = getGitLabTicketsCurrentBalanceFormat(timesBeforeToday, contract)
    setCurrentBalanceData(currentBalanceData)
    */

    const tableData = getTableChartData(timesSelectedDateRange, bookedTimes)
    setTableChartData(tableData)
    setIsLoadingTable(false)

    const calendarData = getCalendarData(trackedTimesAll)
    setCalendarChartData(calendarData)
  }, [selectedRange.start, selectedRange.end, trackedTimesAll, contract, bookedTimes, isStillLoading])

  useEffect(() => {
    if (oAuthError) {
      displayError(oAuthError)
    }
  }, [oAuthError])

  const triggerReloadTimelog = () => {
    setReloadData((prev) => prev + 1)
  }

  const handleLogoutTimelog = () => {
    setCachedTrackedTimes('[]')
    setCachedPageCursor('')
  }

  return (
    <TimelogContext.Provider value={{
      isStillLoading,
      trackedTimesAll,
      highlightedDay,
      setHighlightedDay,
      selectedRange,
      setSelectedRange,
      contract,
      currentBalanceData,
      tableChartData,
      pendingLoggedData,
      calendarChartData,
      barChartData,
      isLoadingBarChart,
      setIsLoadingBarChart,
      isLoadingTable,
      setIsLoadingTable,
      triggerReloadTimelog,
      handleLogoutTimelog,
      reloadData
    }}
    >
      {children}
    </TimelogContext.Provider>
  )
}

TimelogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
