import { getUnixTime, parseJSON, startOfDay } from 'date-fns'
import { formatShortISODate } from './dateHelpers'

export const userTrackedTimesQuery = (username, cursor = '', { startDate, endDate }) => `
{
  timelogs(
    startTime: "${formatShortISODate(startDate)}",
    endTime: "${formatShortISODate(endDate)}",
    username: "${username}",
    first: 300,
    after: "${cursor}"
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      id
      spentAt
      timeSpent
      issue {
        title
        projectId
        webPath
      }
      mergeRequest {
        projectId
        webUrl
        title
      }
    }
  }
}`

export const getQueryProjectTitles = (ids) => {
  const query = `
    {
      projects (ids: [${ids}]) {
        nodes {
          name
          id
        }
      }
    }
  `
  return query
}

const removeDomain = (url) => {
  return url.replace(/https?:\/\/[^/]+/, '')
}

const getProjectShortPath = ({ webPath }) => {
  const path = webPath.match(/^\/(.+)\/-\//)
  if (path && path[1]) {
    return path[1]
  }
  console.error('Could not parse project-short-path from webPath:', webPath)
  return 'unknown-project'
}

const parseGitLabEntries = (prev, entries) => {
  return entries.reduce((acc, { spentAt, timeSpent, issue, mergeRequest, id }) => {
    const projectId = issue ? issue.projectId : mergeRequest.projectId
    const projectTitle = issue ? issue.projectTitle : mergeRequest.title
    const spentAtDate = parseJSON(spentAt)
    const spentAtDateStartOfDay = startOfDay(spentAtDate)
    const timeStamp = getUnixTime(spentAtDateStartOfDay)
    const title = issue ? issue.title : `(MR) ${mergeRequest.title}`
    const webPath = issue ? issue.webPath : removeDomain(mergeRequest.webUrl)
    const projectShortPath = getProjectShortPath({ webPath })
    const bookedEntry = {
      spentAt,
      timeSpent,
      title,
      webPath,
      projectShortPath,
      projectId,
      projectTitle,
      id,
      spendingReason: 'WORK'
    }
    if (acc[timeStamp]) {
      acc[timeStamp].entries.push(bookedEntry)
    } else {
      acc[timeStamp] = {}
      acc[timeStamp].entries = [bookedEntry]
    }
    return acc
  }, prev)
}

const sortDayEntries = (entries) => {
  return Object.keys(entries).reduce((acc, day) => {
    acc[day].entries = acc[day].entries.sort((trackedTimeA, trackedTimeB) => {
      return parseJSON(trackedTimeA.spentAt) - parseJSON(trackedTimeB.spentAt)
    })
    return acc
  }, entries)
}

const accumulateDayTimeSpent = (trackedTimes) => {
  const timeSpent = Object.entries(trackedTimes).reduce((acc, [timestamp, day]) => {
    const validSecondsSpent = day.entries.reduce((acc, entry) => {
      return acc + entry.timeSpent
    }, 0)
    acc[timestamp].totalDayTimeSpent = validSecondsSpent
    return acc
  }, trackedTimes)
  return timeSpent
}

const calculateDaySpendingReason = (entries) => {
  return Object.keys(entries).reduce((acc, day) => {
    const { entries } = acc[day]
    const spendingReasons = entries.reduce((reasons, { spendingReason, timeSpent }) => {
      reasons[spendingReason] += timeSpent
      return reasons
    }, {
      WORK: 0,
      VACATION: 0,
      SICK: 0,
      EDUCATION: 0
    })
    const mainReason = Object.keys(spendingReasons).reduce((acc, key) => {
      return spendingReasons[acc] > spendingReasons[key] ? acc : key
    })
    acc[day].spendingReason = mainReason
    return acc
  }, entries)
}

export const formatTrackedTimes = (contractTimes, entries) => {
  const mergedEntries = parseGitLabEntries(contractTimes, entries)
  const sortedEntries = sortDayEntries(mergedEntries)
  const entriesWithAccumulatedDayTimeSpent = accumulateDayTimeSpent(sortedEntries)
  const trackedTimesWithDaySpendingReason = calculateDaySpendingReason(entriesWithAccumulatedDayTimeSpent)
  return trackedTimesWithDaySpendingReason
}
