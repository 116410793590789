import React, { useContext, useState } from 'react'
import { AuthContext } from '../../oauth/AuthContext'
import Login from './Login'
import {
  FieldTimeOutlined,
  TableOutlined,
  BarChartOutlined,
  DatabaseOutlined,
  ShareAltOutlined,
  PhoneOutlined,
  FundProjectionScreenOutlined,
  EuroOutlined,
  ReadOutlined
} from '@ant-design/icons'
import { Layout, Grid, Menu } from 'antd'
import PageHeader from './PageHeader'
import { useAdmin, MENU_POINTS, MENU_TITLE_MAP } from '../../hooks/useAdmin'
import { useUserPreferences } from '../../hooks/useUserPreferences'
import styled from 'styled-components'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

const { useBreakpoint } = Grid
const { Sider, Content } = Layout

const MenuContainer = styled.div`
  position: sticky;
  top: 16px;
`

const Sidebar = (props) => {
  const screens = useBreakpoint()
  const { token } = useContext(AuthContext)
  const {
    selectedMenuPoint,
    accessPermissions
  } = useAdmin()
  const {
    isSidebarCollpased,
    setIsSidebarCollpased
  } = useUserPreferences()
  const isTimelogSelected = selectedMenuPoint === MENU_POINTS.timelogs
  const initialOpenSubMenu = isTimelogSelected || isSidebarCollpased ? [] : ['admin']
  const [openSubMenus, setOpenSubMenu] = useState(initialOpenSubMenu)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  if (!token) {
    return (
      <Login />
    )
  }
  const divider = (key = 'divider') => ({ key, type: 'divider' })

  const getMenuPoint = (label, key, icon, children) => {
    return { label: MENU_TITLE_MAP[label], key, icon, children }
  }
  const adminMenu = (
    getMenuPoint('admin', '/admin', <TableOutlined />, [
      getMenuPoint('check-violations', '/admin/check-violations'),
      divider('linkDivider'),
      getMenuPoint('contracts', '/admin/contracts'),
      getMenuPoint('vacations', '/admin/vacations'),
      getMenuPoint('sicks', '/admin/sicks'),
      getMenuPoint('educations', '/admin/educations')
    ])
  )

  const hasDashboardAccess = (accessPermissions && accessPermissions.includes('dashboard')) || process.env.REACT_APP_OVERRIDE_ADMIN_ACCESS === 'true'
  const hasBillingAccess = (accessPermissions && accessPermissions.includes('billing')) || process.env.REACT_APP_OVERRIDE_ADMIN_ACCESS === 'true'
  const hasAdminAccess = (accessPermissions && accessPermissions.includes('admin')) || process.env.REACT_APP_OVERRIDE_ADMIN_ACCESS === 'true'

  const menuPoints = [
    getMenuPoint('timelogs', '/timelogs', <FieldTimeOutlined />),
    getMenuPoint('stats', '/stats', <BarChartOutlined />),
    divider('linkDivider'),
    {
      type: 'group',
      label: 'Web Links',
      children: [
        { label: 'Sharebox', key: 'external_https://share.hpm.agency', icon: <ShareAltOutlined /> },
        { label: 'Jitsi', key: 'external_https://meet.hpm.agency', icon: <PhoneOutlined /> },
        { label: 'Status', key: 'external_https://uptime.hpm.agency/status/hpm', icon: <FundProjectionScreenOutlined /> },
        { label: 'Wiki', key: 'external_https://wiki.hpm.agency', icon: <ReadOutlined /> }
      ]
    },
    divider('bottomDivider'),
    (hasDashboardAccess ? getMenuPoint('dashboard', '/dashboard', <DatabaseOutlined />) : null),
    (hasBillingAccess ? getMenuPoint('billing', '/billing', <EuroOutlined />) : null),
    (hasAdminAccess ? adminMenu : null)
  ]

  const handleCollapse = (isCollapsed) => {
    if (!isCollapsed) {
      setOpenSubMenu(isTimelogSelected ? [] : ['admin'])
    }
    setIsSidebarCollpased(isCollapsed)
  }

  const handleSelectMenu = ({ key: path, ...rest }) => {
    if ((path || '').startsWith('external_')) {
      const externalLink = path.replace(/^external_/, '')
      return window.open(externalLink, '_blank', 'noopener,noreferrer')
    }
    return navigate(path)
  }

  const handleOpenSubMenu = (keys) => {
    setOpenSubMenu(keys)
  }

  return (
    <div {...props}>
      <PageHeader />
      <Layout hasSider>
        <Sider
          style={{ zIndex: 1 }}
          collapsible
          collapsed={isSidebarCollpased}
          onCollapse={handleCollapse}
          breakpoint='lg'
          collapsedWidth={screens.xs ? 0 : 80}
          zeroWidthTriggerStyle={{ top: 0 }}
          theme='light'
        >
          <MenuContainer>
            <Menu
              theme='light'
              defaultSelectedKeys={[pathname]}
              selectedKeys={[pathname]}
              mode='inline'
              items={menuPoints}
              inlineCollapsed={isSidebarCollpased}
              onSelect={handleSelectMenu}
              onOpenChange={handleOpenSubMenu}
              openKeys={openSubMenus}
            />
          </MenuContainer>
        </Sider>
        <Content style={{ width: '100%' }}>
          <Outlet />
        </Content>
      </Layout>
    </div>
  )
}

export default Sidebar
