import { Row, Col, Space, Switch, Button, Popover } from 'antd'
import {
  PlusCircleOutlined,
  SettingOutlined
} from '@ant-design/icons'

const TableHeader = ({ onClick, timeFormat, onChangeTimeFormat }) => {
  const content = (
    <Space>
      Hours human-readable
      <Switch checked={timeFormat} onChange={onChangeTimeFormat} />
    </Space>
  )
  return (
    <Row justify='end' align='middle' gutter={[0]}>
      <Col>
        <Popover content={content} placement='bottom' trigger='click'>
          <Button type='text' icon={<SettingOutlined />} />
        </Popover>
      </Col>
      <Col>
        <Button type='primary' icon={<PlusCircleOutlined />} onClick={onClick}>
          Create
        </Button>
      </Col>
    </Row>
  )
}

export default TableHeader
