import { Table, Button, Typography, Tooltip } from 'antd'
import { parseJSON, formatISO } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { useTimelog } from '../../hooks/useTimelog'
import { formatShortISODate, humanizeTime } from '../../lib/dateHelpers'
const { Text } = Typography

const GITLAB_URL = process.env.REACT_APP_GITLAB_URL || 'https://hpm.agency'

const getTrackingOrigin = ({ spendingReason, bookingPeriod, projectShortPath, projectTitle }) => {
  if (spendingReason !== 'WORK') {
    return bookingPeriod
  }
  return projectTitle
}

const formatIssueTitle = ({ webPath, title, spendingReason }) => {
  if (spendingReason !== 'WORK') {
    return (
      <Text type='secondary'>{title}</Text>
    )
  }
  return (
    <Button href={GITLAB_URL + webPath} type='link' target='_blank'>
      {title}
    </Button>
  )
}

const getDateTooltip = ({ spentAt }) => {
  return formatISO(parseJSON(spentAt), 'PPPPpppp')
}

const columns = [
  {
    title: 'issue',
    dataIndex: 'issue',
    key: 'issue',
    render: (_, entry) => (
      formatIssueTitle(entry)
    ),
    width: '40%'
  },
  {
    title: 'ProjectTitle',
    dataIndex: 'projectTitle',
    key: 'projectTitle',
    render: (_, entry) => (
      <Text type='secondary'>
        {getTrackingOrigin(entry)}
      </Text>
    ),
    width: '35%'
  },
  {
    title: 'TimeSpent',
    dataIndex: 'timeSpent',
    key: 'timeSpent',
    render: (_, { timeSpent }) => (
      <Text>
        {humanizeTime(timeSpent)}
      </Text>
    ),
    width: '8%',
    align: 'right'
  },
  {
    title: 'ISO date',
    dataIndex: 'isoDate',
    key: 'isoDate',
    render: (_, { spentAt }) => (
      <Tooltip title={getDateTooltip({ spentAt })}>
        {formatShortISODate(spentAt)}
      </Tooltip>
    ),
    width: '17%',
    align: 'center'
  }
]

const StyledTable = styled((props) => <Table {...props} />)`
  && tbody > tr > td {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: #d2d2d2;
  }
  .table-row-dark {
    background-color: #eaeaea;
  }

  .table-row-highlighted {
    background-color: rgb(0 166 49 / 21%)
  }
`

const TrackedTimesTable = (props) => {
  const {
    highlightedDay,
    tableChartData,
    isLoadingTable
  } = useTimelog()

  const getRowClassName = ({ dayIndex, spentAt }) => {
    let className = ''
    if (dayIndex % 2 !== 0) {
      className += ' table-row-dark'
    }
    if (highlightedDay !== null) {
      const isHighlighted = formatISO(parseJSON(spentAt), { representation: 'date' }) === highlightedDay
      if (isHighlighted) {
        className += ' table-row-highlighted'
      }
    }
    return className
  }

  return (
    <StyledTable
      columns={columns}
      dataSource={tableChartData}
      showHeader={false}
      rowClassName={(entry) => getRowClassName(entry)}
      pagination={false}
      rowKey={(entry) => entry.id}
      size='small'
      loading={isLoadingTable}
      {...props}
    />
  )
}

export default TrackedTimesTable
