import React from 'react'
import { Gauge } from '@ant-design/plots'
import { useTimelog } from '../../hooks/useTimelog'
import { humanizeTime } from '../../lib/dateHelpers'
import { Spin } from 'antd'

const MINIMUM_PERCENTAGE = 0.001

const PendingLoggedToday = (props) => {
  const {
    pendingLoggedData,
    isStillLoading
  } = useTimelog()

  const getDailyPercentage = (seconds) => {
    const percent = 100 / (pendingLoggedData.requiredSecondsToday / seconds)
    return percent / 100
  }

  const getTickLabel = (percentage) => {
    return `${(percentage * (pendingLoggedData.requiredSecondsToday / 60 / 60)).toFixed(1)}`
  }

  const config = {
    width: 700,
    height: 300,
    percent: Math.max(MINIMUM_PERCENTAGE, getDailyPercentage(pendingLoggedData.secondsTrackedToday)),
    range: {
      color: 'l(0) 0:#B8E1FF 1:#3D76DD'
    },
    radius: 1,
    padding: 7,
    innerRadius: 0.8,
    startAngle: Math.PI - 0.5,
    endAngle: 2 * Math.PI + 0.5,
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D000'
        }
      },
      pin: {
        style: {
          stroke: '#D0D0D000'
        }
      }
    },
    axis: {
      tickInterval: 0.25,
      subTickLine: {
        count: 0
      },
      label: {
        formatter: (_, tick, index) => {
          return getTickLabel(tick.value)
        }
      }
    },
    statistic: {
      title: {
        offsetY: 20,
        style: {
          fontSize: '22px',
          color: '#000'
        },
        formatter: () => 'Pending Logged Today'
      },
      content: {
        offsetY: -19,
        style: {
          fontSize: '30px',
          color: '#000'
        },
        formatter: () => {
          return humanizeTime(pendingLoggedData.secondsTrackedToday)
        }
      }
    }
  }
  return (
    <Spin spinning={isStillLoading} {...props}>
      <Gauge {...config} />
    </Spin>
  )
}

export default PendingLoggedToday
