import chroma from 'chroma-js'
import { parseISO, compareDesc, eachDayOfInterval, endOfYear, endOfYesterday, fromUnixTime, getDay, getMonth, getUnixTime, isLastDayOfMonth, isFirstDayOfMonth, startOfDay, startOfYear, differenceInSeconds, isToday } from 'date-fns'
import { formatShortISODate, getNewKwTitle, getWeekNumberXAxis, getWorkingDays, isLastWeekOfMonths, isFirstWeekOfMonths, humanizeTime, unixTimestampToIsoDate } from './dateHelpers'
import { getFutureBookedPeriods } from './formatBookedTimes'
import { round } from './util'

export const MINIMUM_PERCENTAGE = 0.001

/**
 * Calculate the required balance in seconds from contract start date to today
 * @param   {number}   dailyRequiredAverageSeconds
 * @param   {contract} contract
 * @param   {string}   contract.startDate
 * @return  {number}   requiredSecondsUntilToday
 */
export const getRequiredBalanceToday = (dailyRequiredAverageSeconds, { startDate }) => {
  const yesterday = endOfYesterday()
  const pastBusinessDays = getWorkingDays({ startDate, endDate: yesterday }).length
  const requiredSecondsUntilToday = dailyRequiredAverageSeconds * pastBusinessDays
  return requiredSecondsUntilToday
}

const getTicketsSecondsTracked = (trackedTimes) => {
  return Object.entries(trackedTimes).reduce((acc, [dayTimestamp, day]) => {
    acc += day.entries.reduce((totalTimeSpent, { timeSpent }) => {
      totalTimeSpent += timeSpent
      return totalTimeSpent
    }, 0)
    return acc
  }, 0)
}

/**
 * Returns the total amount of seconds
 * of work time spend from START/STOP events before today
 * @param   {workedTimeEvent[]}
 * @return  {number}
 */
export const getTrackedWorkTimeSecondsBeforeToday = (workedTimeEvents) => {
  let reachedToday = false
  let isTimerRunning = false
  let previousStartEventDate = null
  const secondsSpentWithoutToday = workedTimeEvents.reduce((acc, { event, utc }, i) => {
    if (reachedToday) {
      return acc
    }
    if (event === 'Start') {
      if (isToday(utc)) {
        reachedToday = true
        return acc
      }
      isTimerRunning = true
      previousStartEventDate = utc
    }
    if (event === 'Stop' && isTimerRunning) {
      isTimerRunning = false
      if (isToday(utc)) {
        const startOfStopDay = startOfDay(utc)
        const workDurationWithoutToday = differenceInSeconds(
          startOfStopDay,
          previousStartEventDate.utc
        )
        acc += workDurationWithoutToday
      } else {
        const workDuration = differenceInSeconds(
          utc,
          workedTimeEvents[i - 1].utc
        )
        acc += workDuration
      }
    }
    return acc
  }, 0)
  return secondsSpentWithoutToday
}

/**
 * Returns the total amount of seconds
 * of time spend from non WORK entries
 * @param   {nonWorkTimeEntry[]}
 * @return  {number}
 */
const getTrackedNonWorkTimeSeconds = (nonWorkTimeEntries) => {
  const secondsSpent = nonWorkTimeEntries.reduce((acc, { totalDayTimeSpent }) => {
    return acc + totalDayTimeSpent
  }, 0)
  return secondsSpent
}

export const getPendingLoggedChartData = (trackedTimes, contract) => {
  const secondsTrackedToday = getTicketsSecondsTracked(trackedTimes)
  const requiredSecondsToday = contract.requiredDailyAverageHours * 3600
  return {
    secondsTrackedToday,
    requiredSecondsToday
  }
}

const getCurrentBalancePercentage = (offsetHours, { thresholdHours }) => {
  const { red } = thresholdHours
  const min = -red
  const max = red
  const percentage = ((offsetHours - min) * 100) / (max - min)
  const chartPercentage = Math.max(MINIMUM_PERCENTAGE, percentage / 100)
  return chartPercentage
}

// Unused in favor of current balance based on START/STOP worked time events
// /**
//  * Returns the current balance format based on GitLab tickets time trackings
//  * @param   {object[]}             trackedTimes - Array of days with tracked times
//  * @param   {contract}             contract
//  * @return  {currentBalanceFormat}
//  */
// export const getGitLabTicketsCurrentBalanceFormat = (trackedTimes, contract) => {
//   const dailyRequiredAverageSeconds = contract.requiredDailyAverageHours * 60 * 60
//   const currentlyRequiredSeconds = getRequiredBalanceToday(dailyRequiredAverageSeconds, contract)
//   const secondsTracked = getTicketsSecondsTracked(trackedTimes)
//   const secondsBalance = secondsTracked - currentlyRequiredSeconds
//   const balanceOffsetHours = (secondsTracked / 60 / 60) - (currentlyRequiredSeconds / 60 / 60)
//   const chartPercentage = getCurrentBalancePercentage(balanceOffsetHours, contract)
//   return {
//     balanceTitle: humanizeTime(secondsBalance),
//     chartPercentage,
//     thresholdHours: contract.thresholdHours
//   }
// }

/**
 * Returns the current balance format based on all tracked events
 * @param {workedTimeEvent[]}
 * @param {nonWorkTimeEntry[]}
 * @param {contract}
 * @return {currentBalanceFormat}
 */
export const getBalanceInCurrentFormat = (workedTimeEvents, nonWorkTimeEntries, contract) => {
  const dailyRequiredAverageSeconds = contract.requiredDailyAverageHours * 60 * 60
  const currentlyRequiredSeconds = getRequiredBalanceToday(dailyRequiredAverageSeconds, contract)
  const workTimeSecondsTracked = getTrackedWorkTimeSecondsBeforeToday(workedTimeEvents)
  const nonWorkTimeSecondsTracked = getTrackedNonWorkTimeSeconds(nonWorkTimeEntries)
  const secondsTracked = workTimeSecondsTracked + nonWorkTimeSecondsTracked
  const secondsBalance = secondsTracked - currentlyRequiredSeconds
  const balanceOffsetHours = (secondsTracked / 60 / 60) - (currentlyRequiredSeconds / 60 / 60)
  const chartPercentage = getCurrentBalancePercentage(balanceOffsetHours, contract)

  return {
    balanceTitle: humanizeTime(secondsBalance),
    chartPercentage,
    thresholdHours: contract.thresholdHours
  }
}

export const addEmptyDays = ({ start, end }, trackedTimes) => {
  const daysWithinInterval = eachDayOfInterval({ start, end })
  const dayTimestampsWithinRange = daysWithinInterval.map((day) => {
    const dayStart = startOfDay(day)
    const dayTimestamp = getUnixTime(dayStart)
    return dayTimestamp
  })
  const addedEmptyTimestamps = dayTimestampsWithinRange.reduce((acc, dayTimestamp) => {
    acc[dayTimestamp] = trackedTimes[dayTimestamp] || {}
    return acc
  }, {})
  return addedEmptyTimestamps
}

export const getTableChartData = (trackedTimesAll, bookedTimes) => {
  const chartData = []
  if (trackedTimesAll) {
    const trackedTimesData = Object.entries(trackedTimesAll).reverse().reduce((acc, [_, day], dayIndex) => {
      const { entries } = day
      const workEntries = []
      entries.forEach((entry, index) => {
        if (entry.spendingReason !== 'WORK') {
          return acc
        }
        entry.dayIndex = dayIndex
        workEntries.push(entry)
      })
      for (let sortCycle = 0; sortCycle < workEntries.length - 1; sortCycle++) {
        for (let singleEntryIndex = 0; singleEntryIndex < workEntries.length - 1; singleEntryIndex++) {
          const result = compareDesc(parseISO(workEntries[singleEntryIndex].spentAt), parseISO(workEntries[singleEntryIndex + 1].spentAt))
          if (result === 1) {
            const bufferdEntry = workEntries[singleEntryIndex]
            workEntries[singleEntryIndex] = workEntries[singleEntryIndex + 1]
            workEntries[singleEntryIndex + 1] = bufferdEntry
          }
        }
      }
      acc.push(...workEntries)

      return acc
    }, [])
    chartData.unshift(...trackedTimesData)
  }

  if (bookedTimes) {
    const userBookedTimes = getFutureBookedPeriods(bookedTimes)
    chartData.unshift(...userBookedTimes)
  }

  return chartData
}

const HEAT_COLORS = {
  EMPTY: '#E6E6E6',
  // chroma color scales https://gka.github.io/chroma.js/#color-scales
  WORK: ['#EBF1FF', '#003BB4'],
  EDUCATION: ['#02D46A', '#02D46A'],
  VACATION: ['#FFE5D3', '#E18E54'],
  SICK: ['#ff7369', '#eb4236'],
  OTHER: ['#D4D4D4', '#888888']
}

const getDayHeatColor = (spendingReason, timeSpent) => {
  if (timeSpent === 0) {
    return HEAT_COLORS.EMPTY
  }
  const maxSeconds = 43200 // 12 hours
  const percentage = Math.floor((timeSpent / maxSeconds) * 100) / 100
  const [min, max] = HEAT_COLORS[spendingReason] || HEAT_COLORS.OTHER
  const colorScale = chroma.scale([min, max]).correctLightness()
  return colorScale(percentage).hex()
}

const formatCalendarData = (trackedTimesWithEmptyDays) => {
  const WEEKDAY_TO_LABEL = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat'
  }
  const WEEKDAY_SHIFT = { // start week with monday instead of sunday
    0: 6,
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5
  }
  return Object.entries(trackedTimesWithEmptyDays).reduce((acc, [dayTimestamp, day]) => {
    const dayDate = fromUnixTime(dayTimestamp)
    const monthNumber = getMonth(dayDate)
    const weekDayNumber = getDay(dayDate)
    const isoDate = formatShortISODate(dayDate)
    const spendingReason = day.spendingReason || 'None'
    const totalDayTimeSpent = day.totalDayTimeSpent || 0
    const formattedDay = {
      dayTimestamp,
      isoDate,
      tooltipTitle: `${WEEKDAY_TO_LABEL[weekDayNumber]}, ${isoDate} ${getNewKwTitle(dayDate)}`,
      totalDayTimeSpent,
      weekDayNumber: WEEKDAY_SHIFT[weekDayNumber],
      weekNumber: getWeekNumberXAxis(dayDate, monthNumber),
      monthNumber,
      color: getDayHeatColor(spendingReason, totalDayTimeSpent),
      firstWeek: isFirstWeekOfMonths(dayDate, monthNumber),
      firstDay: isFirstDayOfMonth(dayDate),
      lastWeek: isLastWeekOfMonths(dayDate, monthNumber),
      lastDay: isLastDayOfMonth(dayDate),
      spendingReason
    }
    acc.push(formattedDay)
    return acc
  }, [])
}

export const getCalendarData = (trackedTimesAll) => {
  const today = new Date()
  const trackedTimesAllWithEmptyDays = addEmptyDays(
    { start: startOfYear(today), end: endOfYear(today) },
    trackedTimesAll
  )
  const calendarData = formatCalendarData(trackedTimesAllWithEmptyDays)
  return calendarData
}

export const formatBarChartData = (trackedTimesWithEmptyDays) => {
  return Object.entries(trackedTimesWithEmptyDays).reduce((acc, [dayTimestamp, day]) => {
    const { entries } = day
    if (!entries) {
      const emptyDay = {
        dayTimestamp,
        isoDate: unixTimestampToIsoDate(dayTimestamp)
      }
      acc.push(emptyDay)
      return acc
    }
    entries.forEach((trackedEntry) => {
      trackedEntry.dayTimestamp = dayTimestamp
      trackedEntry.hours = round(trackedEntry.timeSpent / 3600, 2)
      trackedEntry.isoDate = unixTimestampToIsoDate(dayTimestamp)
      acc.push(trackedEntry)
    })
    return acc
  }, [])
}

export const getBarChartData = ({ start, end }, trackedTimesSelectedRange) => {
  const trackedTimesSelectedRangeWithEmptyDays = addEmptyDays(
    { start, end },
    trackedTimesSelectedRange
  )
  const barChartData = formatBarChartData(trackedTimesSelectedRangeWithEmptyDays)
  return barChartData
}

export const getAccProjectTime = (chartData, text) => {
  const items = chartData.filter((d) => d.projectShortPath === text)
  const accumulatedTime = items.reduce((acc, item) => {
    if (!item.timeSpent) {
      return acc
    }
    return acc + item.timeSpent
  }, 0)
  return accumulatedTime
}

const listedProjects = []

export const getProjectTitle = (chartData, path) => {
  const issueDataWithGivenPath = chartData.find((chartEntry) => chartEntry.projectShortPath === path)
  const pathAlreadyExists = (listedProjects.find((projectEntry) => projectEntry.path === path))
  const isDoubledProjectTitle = (listedProjects.find((projectEntry) => projectEntry.title === issueDataWithGivenPath.projectTitle))
  if (!issueDataWithGivenPath?.projectTitle || (isDoubledProjectTitle && !pathAlreadyExists)) {
    return (path.length > 20) ? path.slice(0, 18) + '...' : path
  }
  listedProjects.push({ path, title: issueDataWithGivenPath.projectTitle })
  return (issueDataWithGivenPath.projectTitle.length > 20) ? issueDataWithGivenPath.projectTitle.slice(0, 18) + '...' : issueDataWithGivenPath.projectTitle
}

/**
 * A User's contract
 * @typedef   {object} contract
 * @property  {number} businessDays
 * @property  {number} requiredDailyAverageHours
 * @property  {number} requiredHours
 * @property  {string} startDate
 * @property  {string} endDate
 * @property  {{ red: number, yellow: number }} thresholdHours
 */

/**
 * Worked Time Event
 * @typedef   {object} workedTimeEvent
 * @property  {"Start"|"Stop"} event - The event type
 * @property  {string} utc         - The UTC ISO time of the event
 * @property  {number} localOffset - The local offset in minutes
 */

/**
 * Current Balance Component Data
 * @typedef   {object} currentBalanceFormat
 * @property  {string} balanceTitle
 * @property  {number} chartPercentage
 * @property  {object} thresholdHours - The start date of the contract
 * @property  {number} thresholdHours.red
 * @property  {number} thresholdHours.yellow
 */
