import React, { createContext, ReactElement, useEffect, useState } from 'react' // eslint-disable-line
import { validateAuthConfig } from './validateAuthConfig'
import { useLocalStorage } from '../hooks/useLocalStorage'
import {
  fetchTokens,
  fetchWithRefreshToken,
  logIn,
  epochAtSecondsFromNow,
  epochTimeIsPast,
  postRevokeToken
} from './authentication'
import {
  IAuthContext,
  IAuthProvider,
  TInternalConfig,
  TTokenResponse
} from './Types'
import { requestGraphQlApi, tokenHasGraphQlAccess } from './requestGraphQl'

const FALLBACK_EXPIRE_TIME = 600 // 10minutes

export const AuthContext = createContext<IAuthContext>({
  token: '',
  logoutFlow: () => null,
  loginFlow: () => null,
  error: null,
  requestGraphQlData: async () => null,
  loginInProgress: false
})

export const AuthProvider = ({ authConfig, children }: IAuthProvider): ReactElement<any, any> => {
  const [loginInProgress, setLoginInProgress] = useLocalStorage<boolean>('OAUTH_loginInProgress', false)
  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>('OAUTH_refreshToken', undefined)
  const [token, setToken] = useLocalStorage<string>('OAUTH_token', '')
  const [tokenExpire, setTokenExpire] = useLocalStorage<number>(
    'OAUTH_tokenExpire',
    epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME)
  )
  const [error, setError] = useState<string | null>(null) // TODO Array of errors?

  let interval: any

  // Set default values and override from passed config
  const { preLogin = () => null, postLogin = () => null } = authConfig

  const config: TInternalConfig = {
    preLogin: preLogin,
    postLogin: postLogin,
    ...authConfig
  }

  validateAuthConfig(config)

  const logoutFlow = (skipRedirect = false): void => {
    setRefreshToken(undefined)
    setToken('')
    setTokenExpire(epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME))
    setLoginInProgress(false)
    if (config?.revokeEndpoint) {
      postRevokeToken({ config, refreshToken, token })
    }
    if (config?.logoutRedirect && !skipRedirect) {
      window.location.replace(config.logoutRedirect)
    }
  }

  const loginFlow = (): void => {
    setLoginInProgress(true)
    logIn(config)
  }

  const reLogFlow = (): void => {
    logoutFlow(true)
    loginFlow()
  }

  const handleTokenResponse = (response: TTokenResponse): void => {
    setToken(response.access_token)
    setRefreshToken(response.refresh_token)
    setTokenExpire(epochAtSecondsFromNow(response.expires_in || FALLBACK_EXPIRE_TIME))
    setLoginInProgress(false)
  }

  const refreshAccessToken = (): void => {
    if (token && epochTimeIsPast(tokenExpire) && refreshToken) {
      fetchWithRefreshToken({ config, refreshToken })
        .then((result: TTokenResponse) => handleTokenResponse(result))
        .catch((error: string) => {
          console.error(error)
          setError(error)
          reLogFlow()
        })
    }
  }

  const requestGraphQlData = async (query: string, signal: AbortSignal): Promise<Object | Error> => {
    const hasGraphQlAccess = await tokenHasGraphQlAccess(token)

    if (!hasGraphQlAccess) {
      console.error('Token has no GraphQL access')
      logoutFlow()
    }
    const response = await requestGraphQlApi(query, token, signal)
    return response
  }

  // Register the 'check for soon expiring access token' interval (Every minute)
  useEffect(() => {
    interval = setInterval(() => refreshAccessToken(), 60000) // eslint-disable-line
    return () => clearInterval(interval)
  }, [token]) // This token dependency removes the old, and registers a new Interval when a new token is fetched.

  // Runs once on page load
  useEffect(() => {
    if (loginInProgress) {
      // The client has been redirected back from the Auth endpoint with an auth code
      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.get('code')) {
        // This should not happen. There should be a 'code' parameter in the url by now..."
        const error_description = urlParams.get('error_description') ??
          'Bad authorization state. Refreshing the page might solve the issue.'
        console.error(error_description)
        setError(error_description)
        logoutFlow()
      } else {
        // Request token from auth server with the auth code
        fetchTokens(config)
          .then((tokens: TTokenResponse) => {
            handleTokenResponse(tokens)
            window.history.replaceState(null, '', window.location.pathname) // Clear ugly url params
            // Call any postLogin function in authConfig
            if ((config?.postLogin) != null) config.postLogin()
          })
          .catch((error: string) => {
            console.error(error)
            setError(error)
            logoutFlow()
          })
      }
    } else {
      refreshAccessToken() // Check if token should be updated
    }
  }, []) // eslint-disable-line

  return (
    <AuthContext.Provider
      value={{
        token,
        logoutFlow,
        loginFlow,
        error,
        requestGraphQlData,
        loginInProgress
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
