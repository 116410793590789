import { PURPOSES } from './adminData'
import { prettyJsonStringify } from './util'

const repoEndpoint = process.env.REACT_APP_GITLAB_CONTRACT_DATA_REPOSITORY_URL
const targetBranch = process.env.REACT_APP_GITLAB_CONTRACT_DATA_BRANCH

export const getLastCommit = async (token, sinceTimestamp = '') => {
  const url = `${repoEndpoint}/repository/commits?ref_name=${targetBranch}&since=${sinceTimestamp}`
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const { status, ok } = response
    const body = await response.json()
    if (status !== 200) {
      throw new Error(`status: ${status} - Branch:"${targetBranch}" - Message: ${body?.message}`)
    }
    const [lastCommit] = body
    const { id, created_at: createdAt } = lastCommit
    return { ok, id, createdAt }
  } catch (error) {
    console.error(`Error getting last commit - ${error}`)
    return { ok: false, error }
  }
}

const getFilePath = (userId, purpose) => {
  const validPurpose = PURPOSES.includes(purpose)
  if (!validPurpose) {
    throw new Error(`getFilePath - Invalid purpose: ${purpose}, valid purposes: "${PURPOSES.split(', ')}"`)
  }
  const filePath = `user/${userId}/${purpose}/${userId}_${purpose}.json`
  const encodedFilePath = encodeURIComponent(filePath)
  return encodedFilePath
}

export const getFileSha256 = async (token, userId, purpose) => {
  const filePath = getFilePath(userId, purpose)
  const url = `${repoEndpoint}/repository/files/${filePath}?ref=${targetBranch}`
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'HEAD'
    })
    const { status, ok } = response
    if (status !== 200) {
      throw new Error(`Could not get file sha256 - status: ${status} - file path: ${filePath}`)
    }
    const sha256 = response.headers.get('x-gitlab-content-sha256')
    return { ok, sha256 }
  } catch (error) {
    console.error(`Error getting file sha256 | url: ${url} | error: ${error}`)
    return { ok: false, sha256: null }
  }
}

export const getFile = async (token, userId, purpose) => {
  const filePath = getFilePath(userId, purpose)
  const url = `${repoEndpoint}/repository/files/${filePath}/raw?ref=${targetBranch}`
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const body = await response.json()
    const { status, ok } = response
    if (status !== 200) {
      throw new Error(`Could not get file sha256 - status: ${status} - filePath: ${filePath} - message: ${body.message}`)
    }
    const sha256 = response.headers.get('x-gitlab-content-sha256')
    return { ok, body, sha256 }
  } catch (error) {
    const message = `Error getting file | url: ${url} | error: ${error}`
    return { ok: false, body: null, sha256: null, error: message }
  }
}

export const editFile = async (token, userId, purpose, fileContent) => {
  const filePath = getFilePath(userId, purpose)
  const commitMessage = encodeURI(`Flightplan v2 Admin - Edit ${purpose} data for userId ${userId}`)
  const content = prettyJsonStringify(fileContent)
  const url = `${repoEndpoint}/repository/files/${filePath}`
  const payload = {
    branch: targetBranch,
    commit_message: commitMessage,
    content
  }
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'PUT',
      body: JSON.stringify(payload)
    })
    const { file_path: filePath, branch, message, error, error_description: errorDescription } = await response.json()
    const { status, ok } = response
    if (status !== 200) {
      throw new Error(`Could not edit file
      - status: ${status}
      - filePath: ${filePath}
      - message: ${message}
      - error: ${error}
      - description: ${errorDescription}`)
    }
    console.log(`File edited successfully - filePath: ${filePath} - branch: ${branch} - ok: ${ok}`)
    return { ok, filePath, branch }
  } catch (error) {
    throw new Error(`Error editing file - ${error.message}`)
  }
}

export const createFile = async (token, userId, purpose, fileContent) => {
  const filePath = getFilePath(userId, purpose)
  const content = encodeURI(prettyJsonStringify(fileContent))
  const commitMessage = encodeURI(`Flightplan v2 Admin - Create ${purpose} data for userId ${userId}`)
  const url = `${repoEndpoint}/repository/files/${filePath}?branch=${targetBranch}&commit_message=${commitMessage}&content=${content}`
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'POST'
    })
    const { status, ok, error, error_description: errorDescription } = response
    const body = await response.json()
    const { file_path: filePath, branch, message } = body
    if (status !== 201) {
      throw new Error(`Could not create file
      - status: ${status}
      - filePath: ${filePath}
      - message: ${message}
      - error: ${error}
      - description: ${errorDescription}`)
    }
    console.log(`File created successfully - filePath: ${filePath} - branch: ${branch} - ok: ${ok}`)
    return { ok, filePath, branch }
  } catch (error) {
    throw new Error(`Error creating file - ${error.message}`)
  }
}

export const deleteFile = async (token, userId, purpose) => {
  const filePath = getFilePath(userId, purpose)
  const commitMessage = encodeURI(`Flightplan v2 Admin - Delete ${purpose} data for userId ${userId}`)
  const url = `${repoEndpoint}/repository/files/${filePath}?branch=${targetBranch}&commit_message=${commitMessage}`
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'DELETE'
    })
    const { status, ok } = response
    if (status === 400) {
      throw new Error(`File does not exist - statusCode: ${status} - filePath: ${url}`)
    }
    if (status !== 204) {
      throw new Error(`Unknown Error - statusCode: ${status} - filePath: ${url}`)
    }
    console.log(`File deleted successfully - ok: ${ok}`)
    return { ok }
  } catch (error) {
    throw new Error(`Error deleting file - ${error.message}`)
  }
}

const createFileContent = (entryData, usersTimes, deleteFileEntry = false) => {
  const user = usersTimes.find((user) => user.id === entryData.user.id)
  if (!user) {
    throw new Error('createFileContent failed, user not found')
  }
  const existingUserPurposeTimes = user.purposeTimes.find((purposeTime) => purposeTime.purpose === entryData.purpose)
  const isNewEntry = !entryData.id || !existingUserPurposeTimes
  const userPurposeTimesFiltered = isNewEntry
    ? (existingUserPurposeTimes?.body?.userData || [])
    : existingUserPurposeTimes.body.userData.filter((time) => time.id !== entryData.id)

  if (deleteFileEntry) {
    return { userData: userPurposeTimesFiltered }
  }

  const {
    startDate,
    endDate,
    hours,
    hoursVacation,
    user: { id: userid, name, username }
  } = entryData
  const id = isNewEntry ? window.crypto.randomUUID() : entryData.id
  const createdAt = isNewEntry ? new Date() : entryData.createdAt
  return {
    userData: [
      ...userPurposeTimesFiltered,
      {
        id,
        userid,
        username,
        name,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        createdAt: new Date(createdAt).toISOString(),
        updatedAt: new Date().toISOString(),
        hours,
        hoursVacation
      }
    ]
  }
}

export const handleFileUpdating = async (token, entryData, usersTimes, deleteEntry) => {
  const { user: { id: userId }, purpose } = entryData
  if (!PURPOSES.includes(purpose)) {
    throw new Error(`Invalid purpose. Input: ${purpose} - Has to be one of "${PURPOSES.join(', ')}"`)
  }
  const existingFile = await getFileSha256(token, userId, purpose)
  if (existingFile.ok && entryData.sha256 && existingFile.sha256 !== entryData.sha256) {
    throw new Error('File to be edited is not current. Please refresh data.')
  }
  const fileContent = createFileContent(entryData, usersTimes, deleteEntry)
  if (existingFile.ok) {
    return await editFile(token, userId, purpose, fileContent)
  }
  return await createFile(token, userId, purpose, fileContent)
}
