import React from 'react'

const HandleRedirect = (props) => {
  return (
    <div {...props}>
      <h1>Logging in</h1>
      <div>
        Redirecting to complete login...
      </div>
    </div>
  )
}

export default HandleRedirect
