import { startOfMonth, endOfMonth, subMonths, differenceInDays, addMinutes } from 'date-fns'

export const lastMonthFirstDay = startOfMonth(subMonths(new Date(), 1))
export const lastMonthLastDay = endOfMonth(subMonths(new Date(), 1))
const timezoneOffset = Math.abs(new Date().getTimezoneOffset())

const milestoneQuery = `
milestone {
  title
  id
  iid
}`
const epicQuery = `
epic {
  title
  id
  iid
}`
const timelogsQuery = ({ startDate = lastMonthFirstDay, endDate = lastMonthLastDay, groupId, pageCursor = '' }) => `
{
  timelogs(
    after: "${pageCursor}",
    startDate: "${addMinutes(startDate, timezoneOffset).toISOString()}",
    endDate: "${addMinutes(endDate, timezoneOffset).toISOString()}",
    ${groupId ? `groupId: "${groupId}"` : ''}
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        timeSpent
        spentAt
        note { body }
        issue {
          id
          iid
          title
          labels {
            edges {
              node {
                title
              }
            }
          }
          ${milestoneQuery}
          ${epicQuery}
          projectId
          webPath
          webUrl
        }
        mergeRequest {
          iid
          title
          ${milestoneQuery}
          webUrl
          project {
            id
            path
            group {
              id
              name
              path
            }
          }
        }
        user {
          name
          username
        }
      }
    }
  }
}
`

export const getTimelogs = async ({ startDate, endDate, groupId, requestGraphQlData, signal, onProgress }) => {
  let pageCursor = ''
  let isMoreDataAvailable = true
  let timelogNodes = []
  while (isMoreDataAvailable) {
    const query = timelogsQuery({ startDate, endDate, groupId, pageCursor })
    const response = await requestGraphQlData(query, signal)
    pageCursor = response.data.timelogs.pageInfo.endCursor
    isMoreDataAvailable = response.data.timelogs.pageInfo.hasNextPage
    const lastNode = response.data.timelogs.edges[response.data.timelogs.edges.length - 1]
    if (!lastNode) {
      console.log('no last node found at pageCursor:', pageCursor)
      break
    }
    const lastNodeDate = new Date(lastNode.node.spentAt)
    const timePeriodDays = differenceInDays(endDate, startDate)
    const progress = Math.round((differenceInDays(lastNodeDate, startDate) / timePeriodDays) * 100)
    onProgress && onProgress(progress)
    timelogNodes = [...timelogNodes, ...response.data.timelogs.edges]
  }

  return timelogNodes.reduce((acc, { node }) => {
    if (node.user.username === 'flightplan') {
      return acc
    }
    acc.push(node)
    return acc
  }, [])
}

export const groupTimelogsByProject = (timelogs) => {
  return timelogs.reduce((acc, timelog) => {
    if (!timelog.issue && !timelog.mergeRequest) {
      console.log('could not process timelog by project:', timelog)
      return acc
    } else {
      let client
      let project
      if (timelog.issue) {
        const webPathParts = timelog.issue.webPath.split('/')
        client = webPathParts[2]
        project = webPathParts[3]
      } else {
        client = timelog.mergeRequest.project.group.path
        project = timelog.mergeRequest.project.path
      }
      const issueKey = timelog.issue ? timelog.issue.iid : 'MR-' + timelog.mergeRequest.iid
      const title = timelog.issue ? timelog.issue.title : timelog.mergeRequest.title
      const timeSpent = timelog.timeSpent
      // new client
      if (!acc[client]) {
        acc[client] = {
          projects: {
            [project]: {
              timeSpent: timelog.timeSpent,
              issues: {
                [issueKey]: {
                  timeSpent,
                  title,
                  timelogs: [timelog]
                }
              }
            }
          },
          timeSpent
        }
      // new project
      } else if (!acc[client].projects[project]) {
        acc[client].projects[project] = {
          timeSpent,
          issues: {
            [issueKey]: {
              timeSpent,
              title,
              timelogs: [timelog]
            }
          }
        }
        acc[client].timeSpent += timelog.timeSpent
      // new issue
      } else if (!acc[client].projects[project].issues[issueKey]) {
        acc[client].projects[project].timeSpent += timelog.timeSpent
        acc[client].projects[project].issues[issueKey] = { timeSpent, title, timelogs: [timelog] }
        acc[client].timeSpent += timelog.timeSpent
      // existing issue
      } else {
        acc[client].projects[project].issues[issueKey].timeSpent += timelog.timeSpent
        acc[client].projects[project].issues[issueKey].timelogs.push(timelog)
        acc[client].projects[project].timeSpent += timelog.timeSpent
        acc[client].timeSpent += timelog.timeSpent
      }
    }
    return acc
  }, {})
}

export const groupTimelogsByMilestone = (timelogs) => {
  return timelogs.reduce((acc, timelog) => {
    const milestone = (timelog?.issue?.milestone?.title + timelog?.issue?.milestone?.iid) || timelog?.mergeRequest?.milestone?.iid
    if (!milestone) {
      const client = timelog?.issue?.webPath?.split('/')[2] || timelog?.mergeRequest?.project?.group?.path
      if (client && acc[client]) {
        acc[client].unassignableTimeSpent ? acc[client].unassignableTimeSpent += timelog.timeSpent : acc[client].unassignableTimeSpent = timelog.timeSpent
        acc[client].unassignableTimelogs ? acc[client].unassignableTimelogs.push(timelog) : acc[client].unassignableTimelogs = [timelog]
      } else if (client) {
        acc[client] = {
          unassignableTimeSpent: timelog.timeSpent,
          unassignableTimelogs: [timelog],
          timeSpent: 0
        }
      } else {
        console.log('could not process timelog by milestone:', timelog)
      }
      return acc
    } else {
      const client = timelog?.issue?.webPath?.split('/')[2] || timelog?.mergeRequest?.project?.group?.path
      const issueKey = timelog.issue ? timelog.issue.iid : 'MR-' + timelog.mergeRequest.iid
      const title = timelog.issue ? timelog.issue.title : timelog.mergeRequest.title
      const timeSpent = timelog.timeSpent
      const newIssue = { timeSpent, title, timelogs: [timelog], url: timelog.issue?.webUrl || timelog.mergeRequest?.webUrl }
      // new client
      if (!acc[client]) {
        acc[client] = {
          milestones: {
            [milestone]: {
              title: timelog.issue.milestone.title,
              timeSpent,
              issues: {
                [issueKey]: newIssue
              }
            }
          },
          timeSpent
        }
      // new milestone
      } else if (!acc[client].milestones || !acc[client].milestones[milestone]) {
        acc[client].milestones = acc[client].milestones || {}
        acc[client].milestones[milestone] = {
          title: timelog?.issue?.milestone?.title || 'no issues or no title',
          timeSpent,
          issues: {
            [issueKey]: newIssue
          }
        }
        acc[client].timeSpent += timelog.timeSpent
      // new issue
      } else if (!acc[client].milestones[milestone].issues[issueKey]) {
        acc[client].milestones[milestone].timeSpent += timelog.timeSpent
        acc[client].milestones[milestone].issues[issueKey] = newIssue
        acc[client].timeSpent += timelog.timeSpent
      // existing issue
      } else {
        acc[client].milestones[milestone].issues[issueKey].timeSpent += timelog.timeSpent
        acc[client].milestones[milestone].issues[issueKey].timelogs.push(timelog)
        acc[client].milestones[milestone].timeSpent += timelog.timeSpent
        acc[client].timeSpent += timelog.timeSpent
      }
    }
    return acc
  }, {})
}

export const groupTimelogsByEpic = (timelogs) => {
  return timelogs.reduce((acc, timelog) => {
    const epic = timelog?.issue?.epic?.iid || timelog?.mergeRequest?.epic?.iid
    if (!epic) {
      const client = timelog?.issue?.webPath?.split('/')[2] || timelog?.mergeRequest?.project?.group?.path
      if (client && acc[client]) {
        acc[client].unassignableTimeSpent ? acc[client].unassignableTimeSpent += timelog.timeSpent : acc[client].unassignableTimeSpent = timelog.timeSpent
        acc[client].unassignableTimelogs ? acc[client].unassignableTimelogs.push(timelog) : acc[client].unassignableTimelogs = [timelog]
      } else if (client) {
        acc[client] = {
          unassignableTimeSpent: timelog.timeSpent,
          unassignableTimelogs: [timelog],
          timeSpent: 0
        }
      } else {
        console.log('could not process timelog by epic:', timelog)
      }
      return acc
    } else {
      const client = timelog?.issue?.webPath?.split('/')[2] || timelog?.mergeRequest?.project?.group?.path
      const issueKey = timelog.issue ? timelog.issue.iid : 'MR-' + timelog.mergeRequest.iid
      const title = timelog.issue ? timelog.issue.title : timelog.mergeRequest.title
      const timeSpent = timelog.timeSpent
      const newIssue = { timeSpent, title, timelogs: [timelog] }
      // new client
      if (!acc[client]) {
        acc[client] = {
          epics: {
            [epic]: {
              title: timelog.issue.epic.title,
              timeSpent: timelog.timeSpent,
              issues: {
                [issueKey]: newIssue
              }
            }
          },
          timeSpent: timelog.timeSpent
        }
      // new epic
      } else if (!acc[client].epics || !acc[client].epics[epic]) {
        acc[client].epics = acc[client].epics || {}
        acc[client].epics[epic] = {
          title: timelog.issue.epic.title,
          timeSpent,
          issues: {
            [issueKey]: newIssue
          }
        }
        acc[client].timeSpent += timelog.timeSpent
      // new issue
      } else if (!acc[client].epics[epic].issues[issueKey]) {
        acc[client].epics[epic].timeSpent += timelog.timeSpent
        acc[client].epics[epic].issues[issueKey] = newIssue
        acc[client].timeSpent += timelog.timeSpent
      // existing issue
      } else {
        acc[client].epics[epic].issues[issueKey].timeSpent += timelog.timeSpent
        acc[client].epics[epic].issues[issueKey].timelogs.push(timelog)
        acc[client].epics[epic].timeSpent += timelog.timeSpent
        acc[client].timeSpent += timelog.timeSpent
      }
    }
    return acc
  }, {})
}

export const possibleGroupings = ['project', 'milestone', 'epic']

export const formatBillingData = (timelogs, grouping) => {
  if (!possibleGroupings.includes(grouping)) {
    throw new Error(`Invalid grouping: ${grouping}, must be one of ${possibleGroupings.join(', ')}`)
  }
  switch (grouping) {
    case 'milestone':
      return groupTimelogsByMilestone(timelogs)
    case 'epic':
      return groupTimelogsByEpic(timelogs)
    default:
      return groupTimelogsByProject(timelogs)
  }
}

const groupQuery = name => `
{
  groups(search: "${name}") {
    nodes {
      name,
      id
    }
  }
}
`

export const getGroupId = async ({ name, requestGraphQlData }) => {
  const response = await requestGraphQlData(groupQuery(name))
  if (!response.data || !response.data.groups || response.error) {
    throw new Error(`Error getting group id for ${name}: ${response.error || response.data}`)
  }
  return response.data.groups.nodes.find(g => g.name.toLowerCase() === name).id
}
