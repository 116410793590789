import { Button, Divider, List, Row, Select, Space, Spin, Typography } from 'antd'
import { milliseconds, startOfMonth } from 'date-fns'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useAdmin } from '../../hooks/useAdmin'
import { displayError } from '../../lib/util'
import { AuthContext } from '../../oauth/AuthContext'
import DatePicker from '../common/DatePicker'
const { Title, Text, Paragraph } = Typography
const { RangePicker } = DatePicker

const flightplanServiceEndpoint = process.env.REACT_APP_FLIGHTPLAN_SERVICE_ENDPOINT

const Container = styled.div`
  padding: 20px 16px;
  background-color: #FFF;
`

const Content = styled.div`
  max-width: 600px;
`

const isDateInFuture = (current) => {
  const timeNow = new Date()
  const isInFuture = current > timeNow
  if (isInFuture) {
    return true
  }
  return false
}

const fetchWithTimeout = (url, options, timeout) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_resolve, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    )
  ])
}

const sendPostRequest = async ({
  dateRange,
  requestedUsernames,
  token
}) => {
  const url = `${flightplanServiceEndpoint}/webhook`

  try {
    const response = await fetchWithTimeout(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        dateRange,
        requestedUsernames,
        token
      })
    }, milliseconds({ seconds: 10 }))

    if (!response.ok || response.status !== 200) {
      throw new Error('Webhook response not ok')
    }

    const responseData = await response.json()
    return {
      success: true,
      data: responseData
    }
  } catch (error) {
    return {
      success: false,
      error: error.message
    }
  }
}

const CheckViolations = () => {
  const {
    isLoading: isAdminLoading,
    usersTimes
  } = useAdmin()
  const { token } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState([])
  const [dateRange, setDateRange] = useState({
    start: startOfMonth(new Date()),
    end: new Date()
  })
  const [webhookResponse, setWebhookResponse] = useState(null)

  const users = usersTimes.map(user => user)

  const handleCheck = async () => {
    setIsLoading(true)
    const usernames = selectedEmployeeNames.map((name) => {
      const user = users.find((u) => u.name === name)
      return user.username
    })
    try {
      const webhookResponse = await sendPostRequest({
        dateRange,
        requestedUsernames: usernames,
        token
      })
      if (!webhookResponse.success) {
        throw new Error(`Webhook was not successful | ${webhookResponse.error}`)
      }
      setWebhookResponse(webhookResponse.data)
    } catch (error) {
      console.error('Error sending webhook:', error.message)
      displayError('Time validation request failed')
    } finally {
      setIsLoading(false)
    }
  }
  const handleRangePicker = (pickedRange) => {
    if (!pickedRange) {
      setDateRange({ start: null, end: null })
      return
    }
    const [start, end] = pickedRange
    setDateRange({ start, end })
  }

  const showSpinner = isLoading || isAdminLoading

  const canSubmit = (dateRange?.start && dateRange?.end && selectedEmployeeNames.length > 0) || false

  return (
    <Container>
      <Content>
        <Space direction='vertical' size='small' style={{ display: 'flex' }} />
        <Space direction='vertical' size='small' style={{ display: 'flex' }}>
          <Title level={2}>Time Tracking Validation</Title>
          <Paragraph>Select a date range and employee(s) to check</Paragraph>
          <Spin
            size='large'
            spinning={isAdminLoading || isLoading}
          >
            <Space direction='vertical' style={{ display: 'flex' }}>
              <RangePicker
                style={{ width: 300 }}
                onChange={(range) => handleRangePicker(range)}
                defaultValue={dateRange ? [dateRange.start, dateRange.end] : null}
                value={dateRange ? [dateRange.start, dateRange.end] : null}
                disabledDate={isDateInFuture}
              />
              <Row justify='space-between'>
                <Select
                  style={{ width: '70%' }}
                  placeholder='Select user(s)'
                  optionFilterProp='children'
                  onSelect={(name) => setSelectedEmployeeNames((prev) => [...prev, name])}
                  onDeselect={(name) => setSelectedEmployeeNames((prev) => prev.filter((n) => n !== name))}
                  filterOption={(input, user) =>
                    (user?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={users.map((u) => ({ value: u.name, label: `${u.name} (${u.username})` }))}
                  disabled={isLoading}
                  defaultValue={[]}
                  mode='multiple'
                  onClear={() => setSelectedEmployeeNames([])}
                  allowClear
                />
                <Button
                  type='primary'
                  onClick={() => handleCheck(selectedEmployeeNames)}
                  disabled={!canSubmit}
                >
                  Start Check
                </Button>
              </Row>
            </Space>
          </Spin>
          <Divider orientation='left'>Employee Time Tracking Violations</Divider>
          {(webhookResponse === null
            ? <Text type='secondary'>Start check to display violations</Text>
            : <EmployeeTimeViolationsList
                trackingViolations={webhookResponse.trackingViolations}
                isLoading={showSpinner}
              />
                )}
        </Space>
      </Content>
    </Container>
  )
}

const EmployeeTimeViolationsList = ({ trackingViolations, isLoading }) => {
  return (
    <Spin
      size='large'
      spinning={isLoading}
    >
      <Space direction='vertical' size='medium' style={{ display: 'flex' }}>
        {trackingViolations.map((employee, index) => (
          <List
            key={index}
            bordered
            size='default'
            header={
              <Text strong>
                {`${employee.name} (${employee.username}) - violation(s) found on ${employee.workDayViolations.length} day(s)`}
              </Text>
          }
          >
            <List
              size='small'
              dataSource={employee.workDayViolations}
              renderItem={(workDayViolations) => (
                <List.Item>
                  <List
                    header={<Text italic>{workDayViolations.isoDateString}</Text>}
                    size='small'
                    dataSource={workDayViolations.violations}
                    renderItem={(violation) => (
                      <List.Item>
                        - {violation}
                      </List.Item>
                    )}
                  />

                </List.Item>
              )}
            />
          </List>
        ))}
      </Space>
    </Spin>

  )
}

export default CheckViolations
