import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Card, Select, Row, Col, Space } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import DatePicker from '../common/DatePicker'
import Table from './Table'
import { CSVLink } from 'react-csv'
import useBilling from '../../hooks/useBilling'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { lastMonthFirstDay, lastMonthLastDay } from '../../lib/billingData'
const { RangePicker } = DatePicker

const BillingContainer = styled.div`
  margin: 16px;
`

const Billing = () => {
  const { client } = useParams()
  const navigate = useNavigate()
  const [cachedClients, setCachedClients] = useLocalStorage('Flightplan_Billing_Clients', [])
  const [dateRange, setDateRange] = useState({
    startDate: lastMonthFirstDay,
    endDate: lastMonthLastDay
  })
  const [filters, setFilters] = useState({
    groupBy: 'milestone',
    client,
    ...dateRange
  })
  const { isLoading, loadingProgress, formattedData, csvData, getCsvData, exporting, getBillingData } = useBilling(filters)

  useEffect(() => {
    const clients = Object.keys(formattedData).filter((client) => client !== 'unassignable')
    if (clients.length > 1) {
      setCachedClients(clients)
    }
  }, [formattedData, setCachedClients])

  const tableData = Object.keys(formattedData)
    .filter((client) => {
      if (!filters.client) return true
      return client === filters.client
    })
    .map((client) => {
      let totalTimeSpent = formattedData[client].timeSpent
      if (formattedData[client].unassignableTimeSpent) {
        totalTimeSpent = formattedData[client].unassignableTimeSpent + formattedData[client].timeSpent
      }
      return {
        key: client,
        ...formattedData[client],
        timeSpent: totalTimeSpent
      }
    })

  let headers = [
    { label: 'Client', key: 'key' },
    { label: 'Time Spent (h)', key: 'timeSpentHours' }
  ]
  const startDateString = filters.startDate.toISOString().split('T')[0]
  const endDateString = filters.endDate.toISOString().split('T')[0]
  let csvFilename = `billing-from-${startDateString}-to-${endDateString}-${filters.groupBy}s.csv`
  if (filters.client) {
    headers = [
      filters.groupBy === 'project' ? { label: 'Project', key: 'key' } : { label: 'Milestone', key: 'title' },
      { label: 'Time Spent (h)', key: 'timeSpentHours' }
    ]
    csvFilename = `${filters.client}-billing-${startDateString}-${endDateString}-${filters.groupBy}s.csv`
  }

  const rangePresets = {}
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const currentMonth = new Date().getMonth()
  const monthsUntilNow = months.slice(0, currentMonth + 1)
  monthsUntilNow.forEach((month, index) => {
    const monthFirstDay = new Date(new Date().getFullYear(), index, 1)
    const monthLastDay = index === currentMonth ? new Date() : new Date(new Date().getFullYear(), index + 1, 0)
    rangePresets[month] = [monthFirstDay, monthLastDay]
  })

  return (
    <BillingContainer>
      <h1>Billing</h1>
      <Card bordered={false}>
        <Row gutter={16}>
          <Col>
            <Space>
              <RangePicker
                defaultValue={[lastMonthFirstDay, lastMonthLastDay]}
                ranges={rangePresets}
                onChange={(dates) => {
                  if (!dates || !dates.length) return
                  const [start, end] = dates
                  end.setHours(12)
                  setDateRange({ startDate: start, endDate: end })
                }}
                onOpenChange={(open) => {
                  if (!open) {
                    setFilters((prev) => ({ ...prev, ...dateRange }))
                  }
                }}
              />
              <Select
                defaultValue={client || 'All'}
                style={{ width: 120 }}
                onChange={(value) => {
                  if (!value) return
                  if (value === 'All') {
                    navigate('/billing')
                    setFilters((prev) => ({ ...prev, client: null }))
                    return
                  }
                  navigate(`/billing/${value}`)
                  setFilters((prev) => ({ ...prev, client: value }))
                }}
              >
                <Select.Option value='All'>All</Select.Option>
                {cachedClients.map((client) => (
                  <Select.Option key={client} value={client}>{client.toUpperCase()}</Select.Option>
                ))}
              </Select>
              <Select
                defaultValue='milestone'
                style={{ width: 120 }}
                onChange={(value) => {
                  if (!value) return
                  setFilters((prev) => ({ ...prev, groupBy: value }))
                }}
              >
                <Select.Option value='project'>Projects</Select.Option>
                <Select.Option value='milestone'>Milestones</Select.Option>
                <Select.Option value='epic'>Epics</Select.Option>
              </Select>
            </Space>
          </Col>
          <Col flex={1} align='right'>
            <Space>
              <Button icon={<ReloadOutlined />} onClick={getBillingData} />
              <CSVLink
                data={csvData}
                headers={headers}
                asyncOnClick
                filename={csvFilename}
                onClick={async (event, done) => {
                  await getCsvData()
                  if (!exporting && csvData.length) {
                    done()
                  }
                }}
              >
                <Button type='primary' loading={exporting}>Export CSV</Button>
              </CSVLink>
            </Space>
          </Col>
        </Row>
      </Card>
      <Table
        loading={isLoading && { spinning: true, tip: `${loadingProgress}%` }}
        data={tableData}
        groupBy={filters.groupBy}
        selectedClient={filters.client}
      />
    </BillingContainer>
  )
}

export default Billing
