import { notification, Button } from 'antd'

export const capitalizeFirstLetter = (string) => {
  const stringLower = string.toLowerCase()
  return stringLower.charAt(0).toUpperCase() + stringLower.slice(1)
}

export const round = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const scrollToDay = (isoDate) => {
  const spans = document.querySelectorAll('td > span')
  const dayRow = [...spans].findIndex(span => span.innerText === isoDate)
  if (dayRow > -1) {
    spans[dayRow + 1].scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export const displayError = (description = 'description', link) => {
  const btn = link
    ? (
      <Button type='link' size='small' onClick={() => window.open(link.url, '_blank')}>
        {link.title || link}
      </Button>
      )
    : null
  notification.error({
    message: 'Error',
    description,
    placement: 'bottomRight',
    duration: 8,
    btn,
    key: description
  })
}

export const displaySuccess = (description = 'description') => {
  notification.success({
    message: 'Success',
    description,
    placement: 'bottomRight',
    duration: 8
  })
}

export const prettyJsonStringify = (obj) => JSON.stringify(obj, null, 2)
