import { prettyJsonStringify } from './util'

const repoEndpoint = process.env.REACT_APP_GITLAB_TIMETRACKING_DATA_REPOSITORY_URL
const targetBranch = process.env.REACT_APP_GITLAB_TIMETRACKING_DATA_BRANCH

export const getLastCommit = async (token, sinceTimestamp = '') => {
  const url = `${repoEndpoint}/repository/commits?ref_name=${targetBranch}&since=${sinceTimestamp}`
  try {
    const response = await window.fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const { status, ok } = response
    const body = await response.json()
    if (status !== 200) {
      throw new Error(`status: ${status} - Branch:"${targetBranch}" - Message: ${body?.message}`)
    }
    const [lastCommit] = body
    const { id, created_at: createdAt } = lastCommit
    return { ok, id, createdAt }
  } catch (error) {
    console.error(`Error getting last commit - ${error}`)
    return { ok: false, error }
  }
}

const getFilePath = (userId, purpose) => {
  if (purpose.slice(0, 4) === 'year') {
    const year = purpose.slice(5)
    const filePath = `${year}/${userId}.json`
    const encodedFilePath = encodeURIComponent(filePath)
    return encodedFilePath
  }
  throw new Error(`getFilePath - Invalid purpose: ${purpose}"`)
}

export const getFileSha256 = async (token, userId, purpose) => {
  const filePath = getFilePath(userId, purpose)
  const url = `${repoEndpoint}/repository/files/${filePath}?ref=${targetBranch}`
  try {
    const response = await window.fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'HEAD'
    })
    const { ok } = response
    const sha256 = response.headers.get('x-gitlab-content-sha256')
    return { ok, sha256 }
  } catch (error) {
    console.error(`Error getting file sha256 | url: ${url} | error: ${error}`)
    return { ok: false, sha256: null }
  }
}

export const getFile = async (token, userId, purpose) => {
  const filePath = getFilePath(userId, purpose)
  const url = `${repoEndpoint}/repository/files/${filePath}/raw?ref=${targetBranch}`
  try {
    const response = await window.fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    const body = await response.json()
    const { status, ok } = response
    if (status !== 200) {
      throw new Error(`Could not get file sha256 - status: ${status} - filePath: ${filePath} - message: ${body.message}`)
    }
    const sha256 = response.headers.get('x-gitlab-content-sha256')
    return { ok, body, sha256, path: filePath }
  } catch (error) {
    const message = `Error getting file | url: ${url} | error: ${error}`
    return { ok: false, body: null, sha256: null, error: message }
  }
}

export const editFile = async (token, userId, purpose, fileContent, trigger) => {
  const filePath = getFilePath(userId, purpose)
  const commitMessage = `Timetracking - Edit ${purpose} trigger: ${trigger} for user ${userId}`
  const content = prettyJsonStringify(fileContent)
  const url = `${repoEndpoint}/repository/files/${filePath}`
  const payload = {
    branch: targetBranch,
    commit_message: commitMessage,
    content
  }
  try {
    const response = await window.fetch(url, {
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'PUT'
    })
    const { file_path: filePath, branch, message, error, error_description: errorDescription } = await response.json()
    const { status, ok } = response
    if (status !== 200) {
      throw new Error(`Could not edit file
      - status: ${status}
      - filePath: ${filePath}
      - message: ${message}
      - error: ${error}
      - description: ${errorDescription}`)
    }
    console.log(`File edited successfully - filePath: ${filePath} - branch: ${branch} - ok: ${ok}`)
    return { ok, filePath, branch }
  } catch (error) {
    throw new Error(`Error editing file - ${error.message}`)
  }
}

export const createFile = async (token, userId, purpose, fileContent) => {
  const filePath = getFilePath(userId, purpose)
  const content = prettyJsonStringify(fileContent)
  const commitMessage = `Flightplan v2 Admin - Create ${purpose} data for user ${userId}`
  const url = `${repoEndpoint}/repository/files/${filePath}`
  const payload = {
    branch: targetBranch,
    commit_message: commitMessage,
    content
  }
  try {
    const response = await window.fetch(url, {
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      method: 'POST'
    })
    const { status, ok, error, error_description: errorDescription } = response
    const body = await response.json()
    const { file_path: filePath, branch, message } = body
    if (status !== 201) {
      throw new Error(`Could not create file
      - status: ${status}
      - filePath: ${filePath}
      - message: ${message}
      - error: ${error}
      - description: ${errorDescription}`)
    }
    console.log(`File created successfully - filePath: ${filePath} - branch: ${branch} - ok: ${ok}`)
    return { ok, filePath, branch }
  } catch (error) {
    throw new Error(`Error creating file - ${error.message}`)
  }
}
