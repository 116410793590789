import styled from 'styled-components'
import { CaretUpOutlined } from '@ant-design/icons'
import { humanizeTime } from '../../../lib/dateHelpers'
import copy from 'copy-to-clipboard'

const COLORS = {
  red: '#F4664A',
  yellow: '#FAAD14',
  green: '#30BF78'
}

const height = 8

const Container = styled.div`
  position: relative;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
`

const Range = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${height}px;
  background: blue;
  margin-top: 10px;
  background: repeating-linear-gradient(to right, ${COLORS.red}, ${COLORS.yellow}, ${COLORS.green}, ${COLORS.yellow}, ${COLORS.red});
  border-radius: ${height / 2}px;
`

const Tick = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${({ position }) => position}%;
  transform: translate(-2.5px, -6px);
  width: 5px;
  height: ${height}px;
`

const labelWidth = 150
const Label = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${({ position }) => position}%;
  width: ${labelWidth}px;
  transform: translate(-${({ atlimit }) => atlimit === 'top' ? labelWidth : atlimit === 'bottom' ? 0 : labelWidth / 2}px, 5px);
  border: 1px solid black;
  border-radius: 5px;
  background: white;
  cursor: default;

`

const TrackingQuota = ({ value = 1, totalTimeTracked, totalTimeSpent, threshold = 10 }) => {
  const minVal = 100 - threshold
  const maxVal = 100 + threshold
  const range = maxVal - minVal

  const percentage = Number(value * 100)
  let rangeFraction = (percentage > minVal ? percentage - minVal : 0) / range
  if (rangeFraction > 1) {
    rangeFraction = 1
  }
  const position = 100 * rangeFraction
  let atlimit = false
  if (position < 10) {
    atlimit = 'bottom'
  } else if (position > 90) {
    atlimit = 'top'
  }
  const difference = totalTimeTracked - totalTimeSpent
  const handleCopyDifference = () => {
    copy(humanizeTime(difference * 3600))
  }

  return (
    <Container>
      <Range />
      <Tick position={position} title={percentage}>
        <CaretUpOutlined />
      </Tick>
      <Label position={position} atlimit={atlimit} title='Erfassungsquote' onClick={handleCopyDifference}>{Math.round(percentage)}% ({difference > 0 ? '-' : '+'}{humanizeTime(Math.abs(difference * 3600))})</Label>
    </Container>
  )
}

export default TrackingQuota
