import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Spin, Table } from 'antd'
import { green, grey } from '@ant-design/colors'
import { PlayCircleFilled, PauseCircleOutlined, CalendarOutlined } from '@ant-design/icons'
import { startOfYesterday, isAfter, isSameDay, getDate } from 'date-fns'
import { humanizeTime, formatShortISODate, isWorkingDay } from '../../lib/dateHelpers'
import DatePicker from '../common/DatePicker'
import UserTimesTable from './UserTimesTable'
import { sortFloat, sortByUserName, formatTableData } from './dashboardData'
import { useAdmin } from '../../hooks/useAdmin'

const StyledTableContainer = styled.div`
  border-radius: 5px;
  margin: 16px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`

const StyledTable = styled(Table)`
  .clickable-row {
    cursor: pointer;
  }
`

const OnlineIcon = styled(PlayCircleFilled).attrs({
  style: {
    fontSize: 24,
    color: green.primary
  }
})``

const OfflineIcon = styled(PauseCircleOutlined).attrs({
  style: {
    fontSize: 24,
    color: grey[1]
  }
})``

const getTextColorForBalance = (balance, totalHours) => {
  return `hsl(${((1 - Math.min(Math.abs(balance) / (totalHours * 0.04), 1)) * 120)},100%,50%)`
}

const getColumns = (users, controlDate, onChangeDate) => {
  return [
    {
      title: 'Name',
      dataIndex: ['user', 'name'],
      sorter: sortByUserName,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend'],
      filterSearch: true,
      filters: users.map(u => ({ text: `${u.name} (${u.username})`, value: u.name })),
      onFilter: (name, entry) => entry.user.name.indexOf(name) === 0,
      key: 'name'
    },
    {
      title: 'Working',
      dataIndex: 'isWorking',
      sorter: (a, b) => (b.isWorking.toString().length - a.isWorking.toString().length),
      filterSearch: true,
      filters: [
        { text: 'Working', value: true },
        { text: 'Not Working', value: false }
      ],
      onFilter: (value, entry) => entry.isWorking === value,
      render: (_, { isWorking }) => (
        isWorking ? <OnlineIcon /> : <OfflineIcon />
      ),
      key: 'working'
    },
    {
      title: 'Timed Today',
      dataIndex: 'timedSecondsToday',
      sorter: sortFloat('timedSecondsToday'),
      render: (_, { timedSecondsToday }) => (
        `${timedSecondsToday < 0 ? '-' : ''} ${humanizeTime(timedSecondsToday)}`
      ),
      key: 'tracked'
    },
    {
      title: 'Contract Balance',
      dataIndex: 'contractSecondsBalance',
      sorter: sortFloat('contractSecondsBalance'),
      render: (_, { contractSecondsBalance, contractTotalHours }) => (
        <div style={{ fontWeight: 'bold', color: getTextColorForBalance(contractSecondsBalance / 60 / 60, contractTotalHours) }}>{humanizeTime(contractSecondsBalance)}</div>
      ),
      key: 'balance'
    },
    {
      title: 'Vacation Taken (d)',
      dataIndex: 'vacationDaysTaken',
      sorter: sortFloat('vacationDaysTaken'),
      render: (_, { vacationDaysTaken }) => (
        vacationDaysTaken
      ),
      key: 'vacation'
    },
    {
      title: 'Sick Leave Taken (d)',
      dataIndex: 'sickLeaveDaysTaken',
      sorter: sortFloat('sickLeaveDaysTaken'),
      render: (_, { sickLeaveDaysTaken }) => (
        sickLeaveDaysTaken
      ),
      key: 'sick'
    },
    {
      title: `Timed/Tracked (${formatShortISODate(controlDate)})`,
      dataIndex: 'discrepancy',
      sorter: (a, b) => {
        const aDiscrepancy = Math.abs(a.previousTimedSeconds - a.previousTrackedSeconds)
        const bDiscrepancy = Math.abs(b.previousTimedSeconds - b.previousTrackedSeconds)
        return bDiscrepancy - aDiscrepancy
      },
      render: (_, { previousTimedSeconds, previousTrackedSeconds }) => {
        return `${humanizeTime(previousTimedSeconds)} / ${humanizeTime(previousTrackedSeconds)}`
      },
      key: 'discrepancy',
      filterDropdown: () => (
        <div style={{ padding: 8 }} title='select day to view'>
          <DatePicker
            value={controlDate}
            onChange={(date) => onChangeDate(date || startOfYesterday())}
            disabledDate={d => isAfter(d, startOfYesterday())}
            dateRender={(current) => {
              const style = {}
              if (current && isWorkingDay(current) && !isAfter(current, startOfYesterday())) {
                style.border = '1px solid #1890ff'
              }
              return (
                <div className='ant-picker-cell-inner' style={style}>
                  {getDate(current)}
                </div>
              )
            }}
          />
        </div>
      ),
      filterIcon: filtered => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    }
  ]
}

const Dashboard = (props) => {
  const {
    usersTimes,
    isLoading: isAdminLoading,
    progressPercentage,
    usersTrackedTimes,
    controlDate,
    setControlDate
  } = useAdmin()

  const [isProcessing, setIsProcessing] = useState(true)
  const [tableData, setTableData] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const columns = getColumns(usersTimes, controlDate, setControlDate)
    setColumns(columns)
  }, [usersTimes, controlDate, setControlDate])

  useEffect(() => {
    setIsProcessing(true)
    const timeout = setTimeout(() => {
      const tableData = formatTableData(usersTimes, usersTrackedTimes, controlDate)
      setTableData(tableData)

      setIsProcessing(false)
    }, 0)

    return () => {
      clearTimeout(timeout)
    }
  }, [usersTimes, usersTrackedTimes, controlDate])

  const spinState = {
    tip: (progressPercentage > 0 || progressPercentage > 99.9) ? `${progressPercentage.toFixed(2)}%` : 'Processing data...',
    spinning: isAdminLoading || isProcessing
  }

  const checkRowExpandable = (record) => {
    if (!usersTrackedTimes[record.user.username]) {
      return false
    }
    return usersTrackedTimes[record.user.username].some((entry) => isSameDay(new Date(entry.spentAt), controlDate))
  }

  return (
    <div {...props}>
      <Spin
        size='large'
        {...spinState}
      >
        <StyledTableContainer>
          <StyledTable
            columns={columns}
            dataSource={tableData}
            pagination={false}
            showSorterTooltip={false}
            expandable={{
              rowExpandable: checkRowExpandable,
              expandRowByClick: true,
              expandedRowRender: (record) => <UserTimesTable data={usersTrackedTimes[record.user.username]} filterDate={controlDate} />
            }}
          />
        </StyledTableContainer>
      </Spin>
    </div>
  )
}

export default Dashboard
