import React, { useContext } from 'react'
import { AuthContext } from '../../oauth/AuthContext'
import { Button } from 'antd'

const Login = (props) => {
  const { loginFlow } = useContext(AuthContext)
  return (
    <div {...props}>
      <Button type='primary' onClick={(event) => loginFlow()}>
        Login with Gitlab
      </Button>
    </div>
  )
}

export default Login
