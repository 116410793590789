import { createContext, useContext, useState, useEffect } from 'react'
import { getCurrentUser } from '../lib/userContractData'
import { AuthContext } from '../oauth/AuthContext'
import { getFileSha256, getFile, editFile, createFile } from '../lib/accessTrackedTimeRepository'
import { getUnixTime, fromUnixTime, formatISO, parseISO, isToday, intervalToDuration, milliseconds, startOfDay, startOfToday, endOfToday, differenceInSeconds, endOfYesterday } from 'date-fns'
import { displayError } from '../lib/util'
import { useTimelog } from './useTimelog'
import { getBalanceInCurrentFormat } from '../lib/formatChartData'
import { filterWorkedTimerEvents, filterTrackedDays } from '../lib/dateHelpers'

export const TimetrackingContext = createContext()

export const useTimetracking = () => {
  const context = useContext(TimetrackingContext)

  if (context === undefined) {
    throw new Error('useTimetracking must be used within an TimetrackingProvider')
  }

  return context
}

const requiredDailySeconds = 28800 // 8 hours
const requiredBreakTimeInSeconds = 39600 // 11 Hours
const requiredBreakTime = '11h'

export const getIntervalHoursInSeconds = (interval) => {
  return ((interval.hours * 3600) + (interval.minutes * 60) + (interval.seconds))
}

export const getNewFileTimeEntries = async (currentUser, token) => {
  const currentTime = new Date()
  const currentYear = currentTime.getUTCFullYear()
  const userNameShort = currentUser.username
  const fileExists = await getFileSha256(token, userNameShort, `year ${currentYear}`)
  if (!fileExists.ok) {
    return []
  }
  const fileData = await getFile(token, userNameShort, `year ${currentYear}`)
  const timeEntries = fileData.body.timeEntries.map(({ utc, event }, index) => {
    const parsedUtc = parseISO(utc)
    if (isNaN(parsedUtc.getTime())) {
      const errorMessage = `Invalid date: ${utc} at ${event} event)`
      const { REACT_APP_GITLAB_URL: gitlabUrl, REACT_APP_GITLAB_TIMETRACKING_DATA_BRANCH: branch } = process.env
      const fixLink = { title: 'Fix data', url: `${gitlabUrl}/-/ide/project/p/hpm/flightplan/flightplan-data/edit/${branch}/-/${fileData.path}#L${index * 5 + 4}` }
      displayError(errorMessage, fixLink)
      console.error(errorMessage)
    }
    return {
      utc: parseISO(utc),
      event
    }
  })
  return timeEntries
}

export const getWorkedSecondsFromEvents = (workedTimes) => {
  let timerRunning = false
  const timeSpentToday = workedTimes.reduce((acc, { event, utc }, index) => {
    if (event === 'Start') {
      timerRunning = true
    }
    if (event === 'Stop' && timerRunning) {
      timerRunning = false
      const currentDatetime = new Date(utc)
      const previousDatetime = new Date(workedTimes[index - 1].utc)
      const workDuration = differenceInSeconds(currentDatetime, previousDatetime)
      acc += workDuration
    }
    return acc
  }, 0)
  return timeSpentToday
}

export const getUTCData = (date = new Date()) => {
  const timeZoneOffset = (date.getTimezoneOffset() * -1)
  const currentYear = date.getUTCFullYear()
  const utcTimeStamp = date.toISOString()
  return [utcTimeStamp, currentYear, timeZoneOffset]
}

export function TimetrackingProvider ({ children }) {
  const { token, requestGraphQlData } = useContext(AuthContext)
  const [timeStarted, setTimeStarted] = useState(false)
  const [workedTimeSecondsToday, setWorkedTimeSecondsToday] = useState(0)
  const [breakTimeSeconds, setBreakTimeSeconds] = useState(0)
  const [showShortBreakWarning, setShowShortBreakWarning] = useState(false)
  const [isWorkedTimeTodayLoading, setisWorkedTimeTodayLoading] = useState(true)
  const [lastBreakDuration, setLastBreakDuration] = useState('')
  const [startEventIsProcessing, setStartEventIsProcessing] = useState(false)
  const [stopEventIsProcessing, setStopEventIsProcessing] = useState(false)
  const [allTimeEntries, setAllTimeEntries] = useState(null)
  const [timeTrackedByDay, setTimeTrackedByDay] = useState(null)
  const [currentBalanceData, setCurrentBalanceData] = useState({
    balanceTitle: '',
    chartBalance: 0.5,
    thresholdHours: {
      red: 80,
      yellow: 40
    }
  })
  const [reloadData, setReloadData] = useState(0)
  const {
    contract,
    trackedTimesAll,
    reloadData: reloadTimelog
  } = useTimelog()

  useEffect(() => {
    setisWorkedTimeTodayLoading(true)
    if (!token || !contract.startDate) {
      return
    }
    const getWorkedTimeData = async () => {
      try {
        const currentUser = await getCurrentUser(requestGraphQlData)
        const workedTimesEntries = await getNewFileTimeEntries(currentUser, token)
        setTimeStarted(workedTimesEntries.at(-1)?.event === 'Start' || false)
        setAllTimeEntries(workedTimesEntries)
        const eventsByDay = workedTimesEntries?.reduce((acc, t) => {
          if (!t.utc) {
            return acc
          }
          const dayTimestamp = getUnixTime(startOfDay(t.utc))
          if (!acc[dayTimestamp]) {
            acc[dayTimestamp] = []
          }
          acc[dayTimestamp].push(t)
          return acc
        }, {})
        const timeTrackedPerDay = {}
        Object.keys(eventsByDay).forEach(day => {
          // console.log('eventsByDay[day]', eventsByDay[day])
          const seconds = getWorkedSecondsFromEvents(eventsByDay[day].map(event => ({ ...event, utc: event.utc.toISOString() })))
          const datetime = fromUnixTime(day)
          timeTrackedPerDay[day] = {
            datetime,
            dayTimestamp: day,
            isoDate: formatISO(datetime, { representation: 'date' }),
            timeSpent: seconds,
            hours: seconds / 60 / 60,
            type: 'tracked',
            events: eventsByDay[day],
            projectShortPath: 'Timetracking'
          }
        })
        setTimeTrackedByDay(timeTrackedPerDay)
        const workTimerEventsToday = filterWorkedTimerEvents(
          workedTimesEntries,
          { dateRangeStart: startOfToday(), dateRangeEnd: endOfToday() }
        )
        const workedTimeSecondsToday = getWorkedSecondsFromEvents(workTimerEventsToday)
        setWorkedTimeSecondsToday(workedTimeSecondsToday)

        const nonWorkTimeEntriesBeforeToday = Object.values(filterTrackedDays(
          trackedTimesAll,
          { dateRangeStart: parseISO(contract.startDate), dateRangeEnd: endOfYesterday() }
        )).filter(event => event.spendingReason !== 'WORK')

        const currentBalanceFormat = getBalanceInCurrentFormat(workedTimesEntries, nonWorkTimeEntriesBeforeToday, contract)
        setCurrentBalanceData(currentBalanceFormat)
      } catch (error) {
        console.error(error)
        displayError(error.message)
      } finally {
        setisWorkedTimeTodayLoading(false)
      }
    }

    getWorkedTimeData()
  }, [token, contract.startDate, reloadTimelog, reloadData]) // eslint-disable-line

  useEffect(() => {
    if (isWorkedTimeTodayLoading) {
      return
    }
    const lastEventDate = allTimeEntries.at(-1)?.utc
    const updateDisplayedTimes = () => {
      if (!timeStarted && lastEventDate) {
        const secondsSinceStoppedTimer = differenceInSeconds(new Date(), lastEventDate)
        setBreakTimeSeconds(secondsSinceStoppedTimer)
      }
      if (timeStarted) {
        const secondsSinceStartedTimer = differenceInSeconds(new Date(), lastEventDate)
        setWorkedTimeSecondsToday(workedTimeSecondsToday + secondsSinceStartedTimer)
      }
    }
    updateDisplayedTimes()
    const updateInterval = setInterval(updateDisplayedTimes, milliseconds({ seconds: 60 }))
    return () => {
      clearInterval(updateInterval)
    }
  }, [timeStarted, isWorkedTimeTodayLoading]) // eslint-disable-line

  const isEnoughTimeBetweenWorkdays = (timeEntries) => {
    const currentTime = new Date()
    let lastStop = new Date()
    let foundLastStop = false
    let workStarted = false
    for (let index = timeEntries.length - 1; index >= 0; index--) {
      const entryDate = parseISO(timeEntries[index].utc)
      if ((timeEntries[index].event === 'Stop') && !foundLastStop) {
        lastStop = entryDate
        foundLastStop = true
      }
      if ((timeEntries[index].event === 'Start') && isToday(entryDate)) {
        workStarted = true
        break
      }
    }
    if (workStarted) {
      return true
    }
    const breakDuration = intervalToDuration({ start: lastStop, end: currentTime })
    setLastBreakDuration(`${breakDuration.days}d ${breakDuration.hours}h ${breakDuration.minutes}m`)
    if (breakDuration.months > 0 || breakDuration.days > 0) {
      return true
    }
    const breakDurationInSeconds = getIntervalHoursInSeconds(breakDuration)
    if (breakDurationInSeconds > requiredBreakTimeInSeconds) {
      return true
    }
    return false
  }

  const stopTriggerEventIsProcessing = () => {
    setStartEventIsProcessing(false)
    setStopEventIsProcessing(false)
  }

  const handleTrackTime = async (trigger, acceptedBreakWarning = false) => {
    await trackTime(trigger, acceptedBreakWarning)
    setReloadData((prev) => prev + 1)
  }

  const trackTime = async (trigger, acceptedBreakWarning = false) => {
    const triggerIsStart = trigger === 'Start'
    const [utcTimeStamp, currentYear, timeZoneOffset] = getUTCData()
    const timeEntry = { utc: utcTimeStamp, localOffset: timeZoneOffset, event: trigger }
    const currentUser = await getCurrentUser(requestGraphQlData)
    const userNameShort = currentUser.username
    const fileExists = await getFileSha256(token, userNameShort, `year ${currentYear}`)
    if (!fileExists.ok) {
      const createEntryArray = [timeEntry]
      await createFile(token, userNameShort, `year ${currentYear}`, { timeEntries: createEntryArray })
      stopTriggerEventIsProcessing()
      setTimeStarted(triggerIsStart)
      return
    }
    const fileData = await getFile(token, userNameShort, `year ${currentYear}`)
    const timeEntries = fileData.body.timeEntries
    if (timeEntries.at(-1).event === trigger) {
      setTimeStarted(triggerIsStart)
      const errorMessage = `Tracking Interval has already been ${triggerIsStart ? 'started' : 'stopped'}.`
      stopTriggerEventIsProcessing()
      displayError(errorMessage)
      throw new Error(errorMessage)
    }
    if (triggerIsStart && !acceptedBreakWarning && !isEnoughTimeBetweenWorkdays(timeEntries)) {
      setShowShortBreakWarning(true)
      setTimeStarted(false)
      return
    }
    timeEntries.push(timeEntry)
    await editFile(token, userNameShort, `year ${currentYear}`, { timeEntries }, trigger)
    stopTriggerEventIsProcessing()
    setTimeStarted(triggerIsStart)
  }

  return (
    <TimetrackingContext.Provider value={{
      handleTrackTime,
      timeStarted,
      setTimeStarted,
      workedTimeSecondsToday,
      requiredDailySeconds,
      showShortBreakWarning,
      setShowShortBreakWarning,
      isWorkedTimeTodayLoading,
      lastBreakDuration,
      requiredBreakTime,
      startEventIsProcessing,
      setStartEventIsProcessing,
      stopEventIsProcessing,
      setStopEventIsProcessing,
      currentBalanceData,
      breakTimeSeconds,
      allTimeEntries,
      timeTrackedByDay
    }}
    >
      {children}
    </TimetrackingContext.Provider>
  )
}
