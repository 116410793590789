import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AuthContext } from '../oauth/AuthContext'
import { getAdminTableData, getFilteredUsers, getUsersTimes, getUsersTrackedTimes, PURPOSES, PURPOSES_MAP } from '../lib/adminData'
import { getLastCommit, handleFileUpdating } from '../lib/accessRepositoryData'
import { getTableChartData } from '../lib/formatChartData'
import { getUserPermissions } from '../lib/permissions'
import { useLocalStorage } from './useLocalStorage'
import { displayError, displaySuccess } from '../lib/util'
import { getCurrentUser } from '../lib/userContractData'
import { useLocation } from 'react-router-dom'
import { startOfYesterday, subDays, getUnixTime, isMonday, addDays } from 'date-fns'

export const AdminContext = createContext()

export const MENU_POINTS = {
  timelogs: 'timelogs',
  stats: 'stats',
  dashboard: 'dashboard',
  billing: 'billing',
  ...PURPOSES_MAP
}

export const MENU_TITLE_MAP = {
  [MENU_POINTS.timelogs]: 'Timelog',
  [MENU_POINTS.stats]: 'Stats',
  [MENU_POINTS.dashboard]: 'Dashboard',
  [MENU_POINTS.billing]: 'Billing',
  admin: 'Admin',
  [MENU_POINTS.contracts]: 'Contracts',
  [MENU_POINTS.vacations]: 'Vacations',
  [MENU_POINTS.sicks]: 'Sick Days',
  [MENU_POINTS.educations]: 'Education Days',
  'check-violations': 'Validate Times'
}

const getBreadcrumbRoute = (selectedMenuPoint) => {
  if ([MENU_POINTS.timelogs, MENU_POINTS.stats, MENU_POINTS.dashboard, MENU_POINTS.billing].includes(selectedMenuPoint)) {
    return [{ breadcrumbName: MENU_TITLE_MAP[selectedMenuPoint] }]
  }
  return [
    { breadcrumbName: 'Admin' },
    { breadcrumbName: MENU_TITLE_MAP[selectedMenuPoint] }
  ]
}

export const useAdmin = () => {
  const context = useContext(AdminContext)

  if (context === undefined) {
    throw new Error('useAdmin must be used within an AdminProvider')
  }

  return context
}

const defaultControlDate = subDays(startOfYesterday(), isMonday(new Date()) ? 2 : 0)
export function AdminProvider ({ children }) {
  const { requestGraphQlData, token, logoutFlow, error: oAuthError } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [cachedLastCommit, setCachedLastCommit] = useLocalStorage('Flightplan_Admin_Last_Commit', {})
  const [cachedUsersTimes, setCachedUsersTimes] = useLocalStorage('Flightplan_Admin_Users_times', [])
  const [usersTimes, setUsersTimes] = useState([])
  const [partialUserLoaded, setPartialUserLoaded] = useState(0)
  const [adminTableData, setAdminTableData] = useState([])
  const [usersTrackedTimes, setUsersTrackedTimes] = useState([])
  const [reloadData, setReloadData] = useState(0)
  const [controlDate, setControlDate] = useState(defaultControlDate)
  const [accessPermissions, setAccessPermissions] = useState(null)
  const { pathname } = useLocation()
  const pathEnding = pathname.includes('billing') ? 'billing' : pathname.split('/').at(-1) || MENU_POINTS.timelogs

  const [selectedMenuPoint, setSelectedMenuPoint] = useState(pathEnding)

  const checkAccessPermissions = async () => {
    const currentUser = await getCurrentUser(requestGraphQlData) // TODO output currentUser from oAuthProvider
    const userAccessPermissions = getUserPermissions(currentUser.username)
    setAccessPermissions(userAccessPermissions)
  }

  const incrementPartialProgress = () => {
    setPartialUserLoaded((prev) => prev + 1)
  }

  const fetchingNewData = async (signal) => {
    const filteredUsers = await getFilteredUsers(requestGraphQlData, token, signal)
    if (!filteredUsers) {
      return
    }
    setUsers(filteredUsers)

    const allUsersTimes = await getUsersTimes({
      token,
      users: filteredUsers,
      cachedUsersTimes,
      incrementPartialProgress
    })
    if (!allUsersTimes) {
      throw new Error('Flightplan Admin - No user times found')
    }
    console.log(`Flightplan Admin - Caching data for ${allUsersTimes.length} users`)
    setCachedUsersTimes(allUsersTimes)
    const lastCommitTimestamp = cachedLastCommit?.createdAt || ''
    const lastCommit = await getLastCommit(token, lastCommitTimestamp)
    setUsersTimes(allUsersTimes)

    if (!lastCommit.ok) {
      throw new Error('Flightplan Admin - No last commit found')
    }
    setCachedLastCommit(lastCommit)

    return true
  }

  const shouldUserContractTablesLoad = PURPOSES.includes(pathEnding) || pathname.includes('dashboard')
  const isAdminPageViewed = pathname.includes('admin') || pathname.includes('dashboard')

  const getAdminData = async (signal, controlDate = new Date()) => {
    // issue tracked time can change any time so it is loaded fresh
    const usersTrackedTimesRaw = await getUsersTrackedTimes(requestGraphQlData, signal, { startDate: subDays(controlDate, 1), endDate: addDays(controlDate, 1) })
    const usersTrackedTimesTableData = {}
    for (const key in usersTrackedTimesRaw) {
      if (Object.hasOwnProperty.call(usersTrackedTimesRaw, key)) {
        const timestampKey = getUnixTime(controlDate)
        if (usersTrackedTimesRaw[key][timestampKey]) {
          usersTrackedTimesRaw[key] = { [timestampKey]: usersTrackedTimesRaw[key][timestampKey] } // only show yesterday's data
        }
        usersTrackedTimesTableData[key] = getTableChartData(usersTrackedTimesRaw[key])
      }
    }
    setUsersTrackedTimes(usersTrackedTimesTableData)

    // timelog repository data can be cached until last commit has changed
    const cachedCommitExists = cachedLastCommit.id && cachedLastCommit.ok
    if (cachedCommitExists && cachedUsersTimes.length > 0) {
      const lastRepoCommit = await getLastCommit(token)
      if (cachedLastCommit.id === lastRepoCommit.id) {
        console.log('Flightplan Admin - Cached commit_id matches last commit_id from repository, using cached data')
        setUsersTimes(cachedUsersTimes)
        return true
      }
      console.log('Flightplan Admin - Cached commit_id does not match last commit_id from repository, fetching new data')
    }
    return await fetchingNewData(signal)
  }

  useEffect(() => {
    setIsLoading(true)
    if (!token) {
      return
    }
    if (pathEnding !== selectedMenuPoint) {
      setSelectedMenuPoint(pathEnding)
    }
    if (accessPermissions === null) {
      checkAccessPermissions()
    }
    if (!isAdminPageViewed) {
      return
    }
    setPartialUserLoaded(0)
    const controller = new AbortController()
    const { signal } = controller

    getAdminData(signal, controlDate)
      .then((success) => {
        if (success) {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        if (error instanceof DOMException && signal.aborted) {
          return
        }
        console.error(error)
        displayError(error.message)
      })
    return () => {
      controller.abort()
    }
  }, [token, requestGraphQlData, reloadData, controlDate, pathEnding]) // eslint-disable-line

  useEffect(() => {
    if (!shouldUserContractTablesLoad || usersTimes.length === 0) {
      return
    }
    const tableData = getAdminTableData(usersTimes)
    setAdminTableData(tableData[selectedMenuPoint])
  }, [isLoading, usersTimes, selectedMenuPoint, shouldUserContractTablesLoad])

  useEffect(() => {
    oAuthError && displayError(oAuthError)
  }, [oAuthError])

  const handleFileInteraction = async (entryData, deleteEntry) => {
    try {
      const { ok } = await handleFileUpdating(token, entryData, usersTimes, deleteEntry)
      if (!ok) {
        throw new Error('Flightplan Admin - Error updating file')
      }
      displaySuccess('Data updated successfully')
      triggerReloadAdmin()
      return ok
    } catch (error) {
      console.error(error)
      displayError(error.message)
      return { ok: false }
    }
  }

  const triggerReloadAdmin = () => {
    setReloadData((prev) => prev + 1)
  }

  const handleLogoutFlightplan = () => {
    setCachedLastCommit({})
    setCachedUsersTimes([])
    logoutFlow()
  }
  const progressPercentage = ((partialUserLoaded / ((users.length || 1) * PURPOSES.length)) * 100) || 0
  return (
    <AdminContext.Provider value={{
      isLoading,
      users,
      partialUserLoaded,
      usersTimes,
      adminTableData,
      selectedMenuPoint,
      setSelectedMenuPoint,
      handleFileInteraction,
      triggerReloadAdmin,
      breadcrumbRoute: getBreadcrumbRoute(selectedMenuPoint),
      currentMenuTitle: MENU_TITLE_MAP[selectedMenuPoint],
      handleLogoutFlightplan,
      isAdminPageViewed,
      progressPercentage,
      accessPermissions,
      displayError,
      usersTrackedTimes,
      controlDate,
      setControlDate
    }}
    >
      {children}
    </AdminContext.Provider>
  )
}

AdminProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
