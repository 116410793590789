import React from 'react'
import { Gauge } from '@ant-design/plots'
import { Spin } from 'antd'
import chroma from 'chroma-js'
import { useTimetracking } from '../../hooks/useTimetracking'

const CurrentBalance = (props) => {
  const {
    currentBalanceData,
    isWorkedTimeTodayLoading
  } = useTimetracking()

  const COLOR_STEPS = 150

  const COLORS = {
    red: '#F4664A',
    yellow: '#FAAD14',
    green: '#30BF78'
  }

  const tickColors = chroma.scale([
    COLORS.red, COLORS.yellow, COLORS.green, COLORS.green, COLORS.yellow, COLORS.red
  ]).colors(COLOR_STEPS)
  const tickSteps = [...Array(COLOR_STEPS + 1)].map((_, index) => (index / COLOR_STEPS))

  const getTextColor = (percentage) => {
    const middle = 0.5
    const distanceFromMiddle = Math.abs(middle - percentage)
    if (distanceFromMiddle <= 0.15) {
      return COLORS.green
    }
    if (distanceFromMiddle <= 0.35) {
      return COLORS.yellow
    }
    return COLORS.red
  }

  const tickLabelFormat = ({ value }) => {
    const middle = 0.5
    const minusSign = value < middle ? '-' : ''
    const distanceFromMiddle = Math.abs(middle - value)
    if (distanceFromMiddle <= 0.15) {
      return '0'
    }
    const { red, yellow } = currentBalanceData.thresholdHours
    if (distanceFromMiddle <= 0.35) {
      return `${minusSign}${yellow}`
    }
    return `${minusSign}${red}`
  }

  const config = {
    percent: currentBalanceData.chartPercentage,
    height: 300,
    width: 300,
    innerRadius: 0.85,
    type: 'meter',
    meter: {
      steps: COLOR_STEPS,
      stepRatio: 0.99
    },
    range: {
      ticks: tickSteps,
      color: tickColors
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0'
        }
      },
      pin: {
        style: {
          stroke: '#D0D0D0'
        }
      }
    },
    axis: {
      tickInterval: 0.25,
      subTickLine: {
        count: 0
      },
      label: {
        formatter: (_, tick) => {
          return tickLabelFormat(tick)
        }
      }
    },
    statistic: {
      title: {
        formatter: () => {
          return currentBalanceData.balanceTitle
        },
        offsetY: -31,
        style: ({ percent }) => {
          const color = getTextColor(percent)
          return {
            fontSize: '30px',
            color
          }
        }
      },
      content: {
        offsetY: 23,
        style: {
          fontSize: '24px',
          color: '#000'
        },
        formatter: () => 'Balance'
      }
    }
  }

  return (
    <Spin spinning={isWorkedTimeTodayLoading} {...props}>
      <Gauge {...config} />
    </Spin>
  )
}

export default CurrentBalance
