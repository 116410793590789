const USERNAMES_WITH_ADMIN_PERMISSIONS = [
  'tl',
  'fd',
  'hs',
  'db'
]

const USERNAMES_WITH_DASHBOARD_PERMISSIONS = [
  ...USERNAMES_WITH_ADMIN_PERMISSIONS,
  'cb'
]

const USERNAMES_WITH_BILLING_PERMISSION = [
  ...USERNAMES_WITH_ADMIN_PERMISSIONS,
  'lr',
  'rb'
]

export const permissions = {
  admin: USERNAMES_WITH_ADMIN_PERMISSIONS,
  dashboard: USERNAMES_WITH_DASHBOARD_PERMISSIONS,
  billing: USERNAMES_WITH_BILLING_PERMISSION
}

export const getUserPermissions = (username) => {
  const userPermissions = []
  const availablePermissions = Object.keys(permissions)
  availablePermissions.forEach((permission) => {
    const hasPermission = permissions[permission].includes(username)
    if (hasPermission) {
      userPermissions.push(permission)
    }
  })
  return userPermissions
}
