import { useContext, useState, useEffect, useCallback } from 'react'
import { AuthContext } from '../oauth/AuthContext'
import { getTimelogs, formatBillingData, getGroupId } from '../lib/billingData'
import { displayError } from '../lib/util'

const useBilling = ({ client, startDate, endDate, groupBy }) => {
  const { requestGraphQlData } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [exporting, setExporting] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [billingData, setBillingData] = useState([])
  const [formattedData, setFormattedData] = useState({})
  const [csvData, setCsvData] = useState([])

  const getBillingData = useCallback(async () => {
    setIsLoading(true)
    setLoadingProgress(0)
    try {
      let groupId = null
      if (client) {
        groupId = await getGroupId({ name: client, requestGraphQlData })
      }
      const timelogs = await getTimelogs({
        startDate,
        endDate,
        groupId,
        requestGraphQlData,
        onProgress: setLoadingProgress
      })
      setBillingData(timelogs)
    } catch (error) {
      console.error(error)
      displayError(error.message)
    }
    setIsLoading(false)
    setLoadingProgress(0)
  }, [client, startDate, endDate, requestGraphQlData])

  useEffect(() => {
    getBillingData()
  }, [getBillingData])

  useEffect(() => {
    if (billingData.length) {
      setFormattedData(formatBillingData(billingData, groupBy))
    }
  }, [billingData, groupBy])

  const getCsvData = async () => {
    setCsvData([])
    setExporting(true)
    const csvData = Object.keys(client ? formattedData[client][groupBy + 's'] : formattedData).map((entryKey) => {
      const entry = client ? formattedData[client][groupBy + 's'][entryKey] : formattedData[entryKey]
      let timeSpent = entry.timeSpent
      if (!client && entry.unassignableTimeSpent) {
        timeSpent = entry.unassignableTimeSpent + entry.timeSpent
      }
      return {
        key: entryKey,
        timeSpentHours: Number(timeSpent / 3600).toFixed(2),
        ...entry
      }
    })
    if (client && formattedData[client].unassignableTimeSpent) {
      csvData.push({
        key: 'Unassignable',
        title: 'Unassignable',
        timeSpentHours: Number(formattedData[client].unassignableTimeSpent / 3600).toFixed(2),
        timeSpent: formattedData[client].unassignableTimeSpent
      })
    }
    setCsvData(csvData)
    setExporting(false)
    return csvData
  }

  return {
    isLoading,
    exporting,
    loadingProgress,
    billingData,
    getBillingData,
    formattedData,
    getCsvData,
    csvData
  }
}

export default useBilling
