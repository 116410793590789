import React from 'react'
import styled from 'styled-components'
import { Table, Button, Typography, Tooltip } from 'antd'
import { parseJSON, formatISO, isSameDay } from 'date-fns'
import { formatShortISODate, humanizeTime } from '../../lib/dateHelpers'
const { Text } = Typography

const GITLAB_URL = process.env.REACT_APP_GITLAB_URL

const getDateTooltip = ({ spentAt }) => {
  return formatISO(parseJSON(spentAt), 'PPPPpppp')
}

const columns = [
  {
    title: 'issue',
    dataIndex: 'issue',
    key: 'issue',
    render: (_, { webPath, title }) => (
      <Button href={GITLAB_URL + webPath} type='link' target='_blank'>
        {title}
      </Button>
    ),
    width: '40%'
  },
  {
    title: 'ProjectTitle',
    dataIndex: 'projectTitle',
    key: 'projectTitle',
    render: (_, { projectTitle }) => (
      <Text type='secondary'>
        {projectTitle}
      </Text>
    ),
    width: '35%'
  },
  {
    title: 'TimeSpent',
    dataIndex: 'timeSpent',
    key: 'timeSpent',
    render: (_, { timeSpent }) => (
      <Text>
        {humanizeTime(timeSpent)}
      </Text>
    ),
    width: '8%',
    align: 'right'
  },
  {
    title: 'ISO date',
    dataIndex: 'isoDate',
    key: 'isoDate',
    render: (_, { spentAt }) => (
      <Tooltip title={getDateTooltip({ spentAt })}>
        {formatShortISODate(spentAt)}
      </Tooltip>
    ),
    width: '17%',
    align: 'center'
  }
]

const StyledTable = styled((props) => <Table {...props} />)`
  && tbody > tr > td {
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: #d2d2d2;
  }
  .table-row-dark {
    background-color: #eaeaea;
  }
`

const UserTimesTable = ({ data, filterDate, ...restProps }) => {
  const getRowClassName = (entry, index) => {
    let className = ''
    if (index % 2 === 0) {
      className += ' table-row-dark'
    }
    return className
  }

  const filteredData = data.filter((entry) => isSameDay(new Date(entry.spentAt), filterDate)) // only show entries for the selected date

  return (
    <StyledTable
      columns={columns}
      dataSource={filteredData}
      showHeader={false}
      rowClassName={getRowClassName}
      pagination={false}
      rowKey={(entry) => entry.id}
      size='small'
      {...{ ...restProps }}
    />
  )
}

export default UserTimesTable
