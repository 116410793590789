import { getUnixTime, isFuture, parseJSON } from 'date-fns'
import { formatShortISODate, getWorkingDays } from './dateHelpers'
import { capitalizeFirstLetter } from './util'

const REASON_MAP = {
  sicks: 'SICK',
  vacations: 'VACATION',
  educations: 'EDUCATION'
}

export const formatBookedTimes = (contract, bookedTimes) => {
  const allBookedTimes = Object.entries(bookedTimes).reduce((acc, [timeType, entries]) => {
    const reason = REASON_MAP[timeType]
    const timeTypeEntries = entries.reduce((entriesAcc, { startDate, endDate, hours, id }) => {
      const validDaysInRange = getWorkingDays({ startDate, endDate }, contract)
      if (validDaysInRange.length === 0) {
        return entriesAcc
      }
      const averageHoursPerDay = hours / validDaysInRange.length
      const averageHoursInSeconds = averageHoursPerDay * 60 * 60
      const dayEntries = validDaysInRange.reduce((dayAcc, day, index) => {
        const timeStamp = getUnixTime(day)
        const trackingRange = `${formatShortISODate(startDate)} - ${formatShortISODate(endDate)}`
        const title = capitalizeFirstLetter(reason)
        const spendingReason = reason
        const projectShortPath = reason
        const bookedEntry = {
          spentAt: day,
          timeSpent: averageHoursInSeconds,
          title,
          trackingRange,
          id: `${id}-${index}`,
          spendingReason,
          projectShortPath
        }
        if (dayAcc[timeStamp]) {
          dayAcc[timeStamp].entries.push(bookedEntry)
        } else {
          dayAcc[timeStamp] = {}
          dayAcc[timeStamp].entries = [bookedEntry]
        }
        return dayAcc
      }, entriesAcc)
      return { ...entriesAcc, ...dayEntries }
    }, acc)
    return timeTypeEntries
  }, {})
  return allBookedTimes
}

export const getFutureBookedPeriods = (bookedTimes) => {
  const futurePeriods = Object.entries(bookedTimes).reduce((bookingAcc, [timeType, entries]) => {
    const reason = REASON_MAP[timeType]
    const bookingPeriod = entries.reduce((acc, { startDate, endDate, hours, id }) => {
      const endOfBooking = parseJSON(endDate)
      const isEndDateInFuture = isFuture(endOfBooking)
      if (!isEndDateInFuture) {
        return acc
      }
      const bookingPeriod = `${formatShortISODate(startDate)} - ${formatShortISODate(endDate)}`
      const title = capitalizeFirstLetter(reason)
      const timeSpent = hours * 60 * 60
      acc.push({
        bookingPeriod,
        title,
        endDate,
        timeSpent,
        endOfBooking,
        id,
        spendingReason: reason,
        spentAt: endDate
      })
      return acc
    }, [])
    bookingAcc.push(...bookingPeriod)
    return bookingAcc
  }, [])
  const sortedFuturePeriods = futurePeriods.sort((a, b) => b.endOfBooking - a.endOfBooking)
  return sortedFuturePeriods
}
