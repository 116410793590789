import { Spin, Table } from 'antd'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAdmin } from '../../../hooks/useAdmin'
import { formatShortISODate, humanizeTime } from '../../../lib/dateHelpers'
import { EntryModal } from './EntryModal'
import styled from 'styled-components'
import TableHeader from './TableHeader'

const StyledTableContainer = styled.div`
  border-radius: 5px;
  margin: 16px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`

const StyledTable = styled(Table)`
  .clickable-row {
    cursor: pointer;
  }
`

const getColumns = (users, humanReadable) => {
  return [
    {
      title: 'Name',
      dataIndex: ['user', 'name'],
      sorter: (a, b) => (a.user.name.localeCompare(b.user.name)),
      defaultSortOrder: 'ascend',
      filterSearch: true,
      filters: users.map(u => ({ text: `${u.name} (${u.username})`, value: u.name })).sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (name, entry) => entry.user.name.indexOf(name) === 0,
      key: 'name'
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      sorter: (a, b) => (b.startDate - a.startDate),
      render: (_, { startDate }) => (
        formatShortISODate(startDate)
      ),
      key: 'startDate'
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      sorter: (a, b) => (b.endDate - a.endDate),
      render: (_, { endDate }) => (
        formatShortISODate(endDate)
      ),
      key: 'endDate'
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      render: (value) => humanReadable ? humanizeTime(value * 3600) : value,
      sorter: (a, b) => a.hours - b.hours,
      key: 'hours'
    },
    {
      title: 'HoursVacation',
      dataIndex: 'hoursVacation',
      render: (value) => humanReadable ? humanizeTime(value * 3600) : value,
      sorter: (a, b) => a.hoursVacation - b.hoursVacation,
      key: 'hoursVacation'
    }
  ]
}

const AdminTimesTable = (props) => {
  const {
    adminTableData,
    usersTimes,
    handleFileInteraction,
    isLoading: isAdminLoading,
    progressPercentage
  } = useAdmin()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showHumanReadableTime, setShowHumanReadableTime] = useState(true)
  const columns = getColumns(usersTimes, showHumanReadableTime)
  const [selectedEntry, setSelectedEntry] = useState(null)
  const { pathname: urlPathname } = useLocation()

  const handleSubmit = async (entryData, deleteEntry) => {
    setLoading(true)
    const { ok } = await handleFileInteraction(entryData, deleteEntry)
    handleClose()
    setLoading(false)
    return ok
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const handleCreate = () => {
    setSelectedEntry(null)
    setIsModalOpen(true)
  }

  return (
    <div {...props}>
      <EntryModal
        isVisible={isModalOpen}
        loading={loading}
        onSubmit={handleSubmit}
        onClose={handleClose}
        users={usersTimes.map(user => user)}
        entryData={selectedEntry}
        onDelete={handleSubmit}
        key={selectedEntry?.id ? `${selectedEntry.id}-${selectedEntry.updatedAt}` : Math.random()}
      />
      <Spin
        tip={`${progressPercentage.toFixed(2)}%`}
        size='large'
        spinning={isAdminLoading}
      >
        <StyledTableContainer>
          <TableHeader onClick={handleCreate} timeFormat={showHumanReadableTime} onChangeTimeFormat={setShowHumanReadableTime} />
          <StyledTable
            key={`admintable_${urlPathname}`}
            columns={columns}
            dataSource={adminTableData}
            size='small'
            pagination={false}
            showSorterTooltip={false}
            rowClassName={() => 'clickable-row'}
            onRow={(entry) => {
              return {
                onClick: () => {
                  setSelectedEntry(entry)
                  setIsModalOpen(true)
                }
              }
            }}
          />
        </StyledTableContainer>
      </Spin>
    </div>
  )
}

export default AdminTimesTable
