import { Select } from 'antd'
import PropTypes from 'prop-types'

export const UserSelection = ({
  users,
  isDisabled,
  selectedUser,
  onUserSelect
}) => {
  const handleSelect = (value, user) => {
    const choosenUser = users.find(user => user.name === value)
    if (choosenUser) {
      onUserSelect(choosenUser)
    }
  }
  const defaultValue = selectedUser?.name || null
  return (
    <Select
      style={{ width: 220 }}
      showSearch
      placeholder='Select a user'
      optionFilterProp='children'
      onSelect={handleSelect}
      filterOption={(input, user) =>
        (user?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      options={users.map((u) => ({ value: u.name, label: `${u.name} (${u.username})` }))}
      disabled={isDisabled}
      defaultValue={defaultValue}
      value={defaultValue}
    />
  )
}

UserSelection.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    })
  ).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired
  }),
  onUserSelect: PropTypes.func.isRequired
}
