import config from './config'

import { AuthProvider } from '../../oauth/AuthContext'
import { TimelogProvider } from '../../hooks/useTimelog'
import { TimetrackingProvider } from '../../hooks/useTimetracking'
import { UserPreferencesProvider } from '../../hooks/useUserPreferences'
import { AdminProvider } from '../../hooks/useAdmin'

const authConfig = {
  ...config.auth,
  postLogin: () => (window.location.href = `${config.basePath}/`),
  preLogin: () => null
}
const ConfiguredAuthProvider = (props) => <AuthProvider authConfig={authConfig} {...props} />

const providers = [
  ConfiguredAuthProvider,
  UserPreferencesProvider,
  AdminProvider,
  TimelogProvider,
  TimetrackingProvider
]

const combineComponents = (components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}

const AppContextProvider = combineComponents(providers)

export default AppContextProvider
