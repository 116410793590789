import { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { isSameYear, isToday, fromUnixTime, startOfYear, startOfWeek } from 'date-fns'
import { Segmented, Spin } from 'antd'
import { CalendarOutlined, BarChartOutlined } from '@ant-design/icons'
import { groupBy } from '@antv/util'
import DatePicker from '../../common/DatePicker'
import BarChart from './BarChart'
import CalendarHeatMap from './CalendarHeatMap'
import TrackingQuota from './TrackingQuota'
import { useUserPreferences } from '../../../hooks/useUserPreferences'
import { useTimetracking } from '../../../hooks/useTimetracking'
import { useTimelog } from '../../../hooks/useTimelog'
import { getHoursInEntries } from '../../../lib/timetracking'

const { RangePicker } = DatePicker

const DiagramHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
`

const StyledSegmented = styled(Segmented)`
  flex: 0 1 auto;
  margin-right: auto;
`

const StyledRangePicker = styled(RangePicker)`
  position: absolute;
  flex: 0 1 auto;
  left: 50%;
  transform: translateX(-50%);
`

const DiagramContainer = (props) => {
  const [trackedToSpentQuota, setTrackedToSpentQuota] = useState(null)
  const [totalTrackedTime, setTotalTrackedTime] = useState(0)
  const [totalSpentTime, setTotalSpentTime] = useState(0)

  const {
    preferredChart,
    setPreferredChart
  } = useUserPreferences()

  const {
    setSelectedRange,
    selectedRange,
    setIsLoadingBarChart,
    isLoadingBarChart,
    setIsLoadingTable,
    barChartData
  } = useTimelog()

  const {
    timeTrackedByDay
  } = useTimetracking()

  useEffect(() => {
    if (barChartData && barChartData.length && timeTrackedByDay) {
      const groupedByDay = groupBy(barChartData, 'dayTimestamp')
      const totalSpent = Object.values(groupedByDay).reduce((acc, day) => {
        if (isToday((fromUnixTime(day[0].dayTimestamp)))) return acc
        const workEntries = day.filter(entry => entry.spendingReason === 'WORK')
        const { total } = getHoursInEntries(workEntries)
        acc += total
        return acc
      }, 0)
      const totalTracked = Object.values(timeTrackedByDay).reduce((acc, { hours, datetime }) => {
        if (hours && datetime >= selectedRange.start && datetime <= selectedRange.end && !isToday(datetime)) {
          acc += hours
        }
        return acc
      }, 0)
      setTotalSpentTime(totalSpent)
      setTotalTrackedTime(totalTracked)
      setTrackedToSpentQuota(totalSpent / totalTracked)
    }
  }, [barChartData, timeTrackedByDay, selectedRange])

  let dateRange = {
    start: selectedRange.start,
    end: selectedRange.end
  }

  const handleRangePicker = (pickedRange) => {
    const [start, end] = pickedRange
    dateRange = ({ start, end })
  }

  const handleOpenStateChange = (open) => {
    if (!open) {
      setTimeout(function () {
        if (!(_.isEqual(selectedRange, dateRange))) {
          setIsLoadingBarChart(true)
          setIsLoadingTable(true)
        }
        setSelectedRange(dateRange)
      }, 100)
    }
  }

  const checkDisabledDates = (current) => {
    const timeNow = new Date()
    const isInFuture = current > timeNow
    if (isInFuture) {
      return true
    }
    const isCurrentYear = isSameYear(current, timeNow)
    if (!isCurrentYear) {
      return true
    }
    return false
  }

  const isBarChartSelected = preferredChart === 'BAR_CHART'

  const rangePresets = {}
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const currentMonth = new Date().getMonth()
  const monthsUntilNow = months.slice(0, currentMonth + 1)
  monthsUntilNow.forEach((month, index) => {
    const monthFirstDay = new Date(new Date().getFullYear(), index, 1)
    const monthLastDay = index === currentMonth ? new Date() : new Date(new Date().getFullYear(), index + 1, 0)
    rangePresets[month] = [monthFirstDay, monthLastDay]
  })
  rangePresets['Full year'] = [startOfYear(new Date()), new Date()]
  rangePresets['Current Week'] = [startOfWeek(new Date()), new Date()]

  return (
    <div {...props}>
      <DiagramHeader>
        <StyledSegmented
          defaultValue={preferredChart}
          onChange={(value) => setPreferredChart(value)}
          options={[
            {
              label: 'Bars',
              value: 'BAR_CHART',
              icon: <BarChartOutlined />
            },
            {
              label: 'Calendar',
              value: 'CALENDAR_CHART',
              icon: <CalendarOutlined />
            }
          ]}
        />
        {isBarChartSelected && (
          <StyledRangePicker
            defaultValue={[selectedRange.start, selectedRange.end]}
            onChange={(range) => handleRangePicker(range)}
            disabledDate={checkDisabledDates}
            onOpenChange={(open) => handleOpenStateChange(open)}
            ranges={rangePresets}
          />
        )}
      </DiagramHeader>
      {(isBarChartSelected
        ? <Spin spinning={isLoadingBarChart}><BarChart /></Spin>
        : <CalendarHeatMap />
      )}
      {trackedToSpentQuota && <TrackingQuota value={trackedToSpentQuota} totalTimeTracked={totalTrackedTime} totalTimeSpent={totalSpentTime} />}
    </div>
  )
}

export default DiagramContainer
