import styled from 'styled-components'
import { Col, Row, Statistic, Spin } from 'antd'
import { red, green, blue, purple } from '@ant-design/colors'
import { Pie } from '@ant-design/plots'
import { ToolOutlined, CarryOutOutlined, FrownOutlined, CoffeeOutlined, BulbOutlined, CheckCircleOutlined } from '@ant-design/icons'

import { useTimelog } from '../../hooks/useTimelog'
import { useTimetracking } from '../../hooks/useTimetracking'

import { getTrackedWorkTimeSecondsBeforeToday } from '../../lib/formatChartData'

const Loading = styled(Spin)`
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 16px;
`

const Content = styled.div`
  min-width: 300px;
  background-color: #f3f3f3;
  width: 100%;
  @media (min-width: 1200px) {
    padding-inline: 1vw;
  }
`

const StyledBox = styled.div`
  background-color: #FFF;
  padding: 32px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`

const CenteredContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

const plotColors = {
  sickLeave: red[3],
  vacation: green[5],
  education: purple[3],
  worked: blue[5],
  remaining: 'rgba(0, 0, 0, 0.3)'
}

const plotLegendTitles = {
  sickLeave: 'Sick Leave',
  vacation: 'Vacation',
  education: 'Education',
  worked: 'Worked',
  remaining: 'Remaining'
}

const CustomIcon = ({ component: Component, size, color, ...restProps }) => {
  return (
    <Component style={{
      fontSize: size || 48,
      color: color || 'rgba(0, 0, 0, 0.45)'
    }}
    />
  )
}

const getTrackedDays = (trackedTimes, spendingReason, secondsInAWorkDay = 8 * 60 * 60) => {
  const spendSeconds = trackedTimes
    .filter(trackedTime => trackedTime.spendingReason === spendingReason)
    .reduce((sum, trackedTime) => {
      return sum + trackedTime.totalDayTimeSpent
    }, 0)

  if (spendSeconds === 0) {
    return 0
  }

  const spendDays = spendSeconds / secondsInAWorkDay

  return spendDays
}

const Stats = (props) => {
  const { trackedTimesAll, contract, isStillLoading } = useTimelog()
  const { allTimeEntries, isWorkedTimeTodayLoading } = useTimetracking()

  const trackedTimesAllList = Object.values(trackedTimesAll)

  const requiredDailyAverageSeconds = contract?.requiredDailyAverageHours * 60 * 60
  const sickLeaveDays = getTrackedDays(trackedTimesAllList, 'SICK', requiredDailyAverageSeconds)
  const vacationDays = getTrackedDays(trackedTimesAllList, 'VACATION', requiredDailyAverageSeconds)
  const educationDays = getTrackedDays(trackedTimesAllList, 'EDUCATION', requiredDailyAverageSeconds)
  const wholeDaysWorkedUntilToday = allTimeEntries ? Math.floor(getTrackedWorkTimeSecondsBeforeToday(allTimeEntries) / requiredDailyAverageSeconds) : 0

  const requiredDailyAverageHours = contract?.requiredDailyAverageHours
  const requiredDaysOfWork = contract?.requiredHours / requiredDailyAverageHours
  const remainingDaysOfWorkThisYear = requiredDaysOfWork - sickLeaveDays - vacationDays - wholeDaysWorkedUntilToday - educationDays

  const config = {
    width: 600,
    height: 383,
    appendPadding: 10,
    theme: {
      colors10: Object.values(plotColors)
    },
    data: isStillLoading
      ? []
      : [
          { type: plotLegendTitles.sickLeave, value: sickLeaveDays },
          { type: plotLegendTitles.vacation, value: vacationDays },
          { type: plotLegendTitles.education, value: educationDays },
          { type: plotLegendTitles.worked, value: wholeDaysWorkedUntilToday },
          { type: plotLegendTitles.remaining, value: remainingDaysOfWorkThisYear }
        ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    legend: {
      selected: {
        [plotLegendTitles.sickLeave]: !!sickLeaveDays,
        [plotLegendTitles.vacation]: !!vacationDays,
        [plotLegendTitles.education]: !!educationDays,
        [plotLegendTitles.worked]: !!wholeDaysWorkedUntilToday,
        [plotLegendTitles.remaining]: !!remainingDaysOfWorkThisYear
      }
    },
    label: {
      type: 'spider',
      labelHeight: 32,
      content: '{name}'
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ]
  }

  return (
    <Container {...props}>
      <Loading spinning={isStillLoading || isWorkedTimeTodayLoading}>
        <Content>
          <Row gutter={[32, 32]}>
            <Col md={24} lg={24} xl={24} xxl={12}>
              <StyledBox>
                <CenteredContent>
                  <Pie {...config} />
                </CenteredContent>
              </StyledBox>
            </Col>
            <Col md={24} lg={24} xl={24} xxl={12}>
              <Row gutter={[32, 32]}>
                <Col span={12}>
                  <StyledBox>
                    <Statistic title='Days of Sick Leave' value={sickLeaveDays} />
                    <CustomIcon component={FrownOutlined} color={plotColors.sickLeave} />
                  </StyledBox>
                </Col>
                <Col span={12}>
                  <StyledBox>
                    <Statistic title='Days of Vacation' value={vacationDays} />
                    <CustomIcon component={CoffeeOutlined} color={plotColors.vacation} />
                  </StyledBox>
                </Col>
                <Col span={12}>
                  <StyledBox>
                    <Statistic title='Days of Education' value={educationDays} />
                    <CustomIcon component={BulbOutlined} color={plotColors.education} />
                  </StyledBox>
                </Col>
                <Col span={12}>
                  <StyledBox>
                    <Statistic title='Days of Work' value={wholeDaysWorkedUntilToday} />
                    <CustomIcon component={CheckCircleOutlined} color={plotColors.worked} />
                  </StyledBox>
                </Col>
                <Col span={12}>
                  <StyledBox>
                    <Statistic title='Required Days of Work' value={requiredDaysOfWork} />
                    <CustomIcon component={ToolOutlined} color={plotColors.remaining} />
                  </StyledBox>
                </Col>
                <Col span={12}>
                  <StyledBox>
                    <Statistic title='Required Daily Average' value={requiredDailyAverageHours} suffix='h' />
                    <CustomIcon component={CarryOutOutlined} color={plotColors.remaining} />
                  </StyledBox>
                </Col>
              </Row>
            </Col>

          </Row>
        </Content>
      </Loading>
    </Container>
  )
}

export default Stats
