import { subSeconds, addSeconds, addDays, addMilliseconds, addWeeks, eachDayOfInterval, endOfDay, formatISO, fromUnixTime, getDay, getMonth, getWeek, getWeeksInMonth, isAfter, isBefore, isWeekend, isWithinInterval, parseJSON, startOfDay, startOfMonth, startOfYear, subMilliseconds } from 'date-fns'
import { de } from 'date-fns/locale/index.js' // https://github.com/date-fns/date-fns/issues/2964
import Holidays from 'date-holidays'
import humanizeDuration from 'humanize-duration'

export const DAILY_SECOND_LIMIT = 36000

const hd = new Holidays()
hd.init('DE', 'he')

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms'
    }
  }
})

export const getCalendarWeekNumber = (date = new Date()) => {
  const calendarWeek = getWeek(date, { locale: de })
  const paddedWeekNumber = calendarWeek.toString().padStart(2, '0')
  return paddedWeekNumber
}

export const filterTrackedDays = (daysToFilter, { dateRangeStart, dateRangeEnd = new Date() } = {}) => {
  if (!dateRangeStart) {
    return daysToFilter
  }

  if (isBefore(dateRangeEnd, dateRangeStart)) {
    throw new Error('dateRangeEnd must be after dateRangeStart')
  }

  const localeAdjustedDateRangeStart = subMilliseconds(startOfDay(dateRangeStart), 1)
  const localeAdjustedDateRangeEnd = addMilliseconds(endOfDay(dateRangeEnd), 1)

  const daysInDateRange = Object.keys(daysToFilter).reduce((acc, day) => {
    const isAfterRangeBegin = isAfter(fromUnixTime(day), localeAdjustedDateRangeStart)
    if (!isAfterRangeBegin) {
      return acc
    }
    const isBeforeRangeEnd = isBefore(fromUnixTime(day), localeAdjustedDateRangeEnd)
    if (!isBeforeRangeEnd) {
      return acc
    }
    acc[day] = daysToFilter[day]
    return acc
  }, {})
  return daysInDateRange
}

export const getWeekNumberXAxis = (date, monthNumber) => {
  const weekNumber = getWeek(date, { locale: de })
  if (weekNumber === 52 && monthNumber === 0) {
    return 0
  }
  return weekNumber
}

export const isLastWeekOfMonths = (date, monthIndex) => {
  const nextWeek = addWeeks(date, 1)
  const monthNextWeekNumber = getMonth(nextWeek)
  return monthNextWeekNumber !== monthIndex
}

export const isFirstWeekOfMonths = (date, monthIndex) => {
  const weekBevore = addWeeks(date, -1)
  const monthWeekBevoreNumber = getMonth(weekBevore)
  return monthWeekBevoreNumber !== monthIndex
}

export const secondsToHourFractionString = (seconds) => {
  const hours = seconds / 60 / 60
  return `${hours.toFixed(2)} hrs`
}

export const getNewKwTitle = (date = new Date()) => {
  const nextWeekNumber = getWeek(
    addDays(
      (date),
      7
    ),
    { locale: de }
  )
  const paddedWeekNumber = nextWeekNumber.toString().padStart(2, '0')
  return `KW${paddedWeekNumber}`
}

export const isWithinContract = (date, contract) => {
  const { startDate, endDate } = contract
  const parsedStartDate = typeof startDate === 'string' ? parseJSON(startDate) : startDate
  const parsedEndDate = typeof endDate === 'string' ? parseJSON(endDate) : endDate
  const parsedDate = typeof date === 'string' ? parseJSON(date) : date
  const isWithinContract = isWithinInterval(parsedDate, { start: parsedStartDate, end: parsedEndDate })
  return isWithinContract
}

export const isWorkingDay = (date, contract) => {
  const parsedDate = typeof date === 'string' ? parseJSON(date) : date
  if (contract && !isWithinContract(parsedDate, contract)) {
    return false
  }
  if (isWeekend(parsedDate)) {
    return false
  }

  const holiday = hd.isHoliday(parsedDate)
  if (holiday && holiday.some((h) => h.type === 'public')) {
    return false
  }
  return true
}

export const getWorkingDays = ({ startDate, endDate }, contract) => {
  const parsedStartDate = typeof startDate === 'string' ? parseJSON(startDate) : startDate
  const parsedEndDate = typeof endDate === 'string' ? parseJSON(endDate) : endDate
  const days = eachDayOfInterval({ start: parsedStartDate, end: parsedEndDate })
  const workingDays = days.filter((day) => isWorkingDay(day, contract))
  return workingDays
}

export const formatShortISODate = (date) => {
  try {
    const parsedDate = typeof date === 'string' ? parseJSON(date) : date
    return formatISO(parsedDate, { representation: 'date' })
  } catch (error) {
    throw new Error(`formatShortISODate error - inputDate: ${date} - Error ${error}}`)
  }
}

export const unixTimestampToIsoDate = (unixTimestamp) => {
  const date = fromUnixTime(unixTimestamp)
  return formatShortISODate(date)
}

const WEEKDAYS_MAP = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
}

export const getWeekDaysWithinRangeFromIso = (unixTimestamp) => {
  const date = fromUnixTime(unixTimestamp)
  const weekday = WEEKDAYS_MAP[getDay(date)]
  return weekday
}

export const getMonthLabel = (weekNumber) => {
  const date = addWeeks(startOfYear(new Date()), weekNumber)
  const weeksInMonth = getWeeksInMonth(date, { weekStartsOn: 0 })
  const middleOfMonth = Math.ceil(weeksInMonth / 2) - 1
  const dateOffset = addWeeks(startOfMonth(date), middleOfMonth)
  const weekWithLabel = getWeek(dateOffset, { locale: de }).toString()
  const shortMonth = date.toLocaleString('en-us', { month: 'narrow' })
  if (weekNumber !== weekWithLabel) {
    return ''
  }
  return shortMonth
}

export const humanizeTime = (seconds) => {
  const sign = seconds < 0 ? '-' : ''
  const value = shortEnglishHumanizer((seconds * 1000), { units: ['h', 'm'], round: true }).replace(/,|\s/g, '').replace(/(\d+[hd])/g, '$1 ')
  return `${sign}${value}`
}

/**
 * Filter workedTimes by dateRange
 * @param   {object[]}                                      workedTimes
 * @param   {{ dateRangeStart: Date, dateRangeEnd: Date }}  dateRange
 * @return  {object[]}                                      workedTimes
 */
export const filterWorkedTimerEvents = (workedTimes, { dateRangeStart, dateRangeEnd }) => {
  if (!workedTimes) {
    return []
  }
  if (isBefore(dateRangeEnd, dateRangeStart)) {
    throw new Error('dateRangeEnd must be after dateRangeStart')
  }
  return workedTimes.reduce((acc, { utc, event }) => {
    const isDuringRange = isWithinInterval(
      new Date(utc),
      { start: subSeconds(dateRangeStart, 1), end: addSeconds(dateRangeEnd, 1) }
    )
    if (!isDuringRange) {
      return acc
    }
    acc.push({ event, utc })
    return acc
  }, [])
}
