export const requestGraphQlApi = async (query: string, token: string, signal?: AbortSignal): Promise<Object | any> => { // TODO take options as parameter
  const response = await fetch('https://hpm.agency/api/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ query }),
    signal
  })

  if (response.status !== 200) {
    throw new Error(`GraphQL request failed with status ${response.status}`)
  }
  return await response.json()
}

export const tokenHasGraphQlAccess = async (token: string): Promise<boolean> => {
  const response = await requestGraphQlApi(returnCurrentUserQuery(), token)
  if (!response?.data?.currentUser) {
    return false
  }
  return true
}

const returnCurrentUserQuery = (): string => {
  return `{
  currentUser {
    id
  }
}`
}
