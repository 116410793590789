import './index.css'
import HandleRedirect from '../HandleRedirect'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AppContextProvider from './AppContextProvider'

import Sidebar from '../Sidebar'
import Timelogs from '../Timelogs'
import Stats from '../Stats'
import Dashboard from '../Dashboard'
import AdminTimesTable from '../Admin/AdminTimesTable'
import CheckViolations from '../Admin/CheckViolations'
import Billing from '../Billing'

import config from './config'

const App = (props) => {
  return (
    <BrowserRouter basename={config.basePath} {...props}>
      <AppContextProvider>
        <Routes>
          <Route path='/' element={<Sidebar />}>
            <Route index element={<Navigate replace to='/timelogs' />} />
            <Route path='timelogs' element={<Timelogs />} />
            <Route path='stats' element={<Stats />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='admin' element={<Navigate replace to='/admin/contracts' />} />
            <Route path='admin/check-violations' element={<CheckViolations />} />
            <Route path='admin/:tableType' element={<AdminTimesTable />} />
            <Route path='billing' element={<Billing />} />
            <Route path='billing/:client' element={<Billing />} />
          </Route>
          <Route
            path='/auth-redirect'
            element={<HandleRedirect />}
          />
          <Route
            path='*'
            element={<h1>404, Not Found!</h1>}
          />
        </Routes>
      </AppContextProvider>
    </BrowserRouter>
  )
}

export default App
