import React from 'react'
import styled from 'styled-components'
import { Table, Button, Typography } from 'antd'
import { humanizeTime } from '../../lib/dateHelpers'
import ExpandedTable from './ExpandedTable'
const { Text } = Typography

const GITLAB_URL = process.env.REACT_APP_GITLAB_URL

export const LinkButton = styled(Button).attrs({ type: 'link' })`
  padding: 0;
`

export const timeSpentColumn = {
  title: 'Time Spent',
  dataIndex: 'timeSpent',
  key: 'timeSpent',
  render: (_, { timeSpent }) => (
    <Text>
      {humanizeTime(timeSpent)}
    </Text>
  ),
  width: '130px',
  align: 'right',
  sorter: (a, b) => a.timeSpent - b.timeSpent
}

const clientColumns = [
  {
    title: 'Client',
    dataIndex: 'key',
    key: 'client',
    render: (_, { key }) => (
      <LinkButton href={GITLAB_URL + '/p/' + key} target='_blank'>
        {key.toUpperCase()}
      </LinkButton>
    )
  },
  timeSpentColumn
]

const BillingTable = ({ data, groupBy, selectedClient, ...restProps }) => {
  return (
    <Table
      columns={clientColumns}
      dataSource={data}
      pagination={false}
      rowKey='key'
      expandable={{
        expandedRowRender: (record) => (<ExpandedTable record={record} groupBy={groupBy} />),
        expandRowByClick: true,
        expandedRowKeys: selectedClient ? [selectedClient] : null,
        showExpandColumn: !selectedClient
      }}
      {...restProps}
    />
  )
}

export default BillingTable
