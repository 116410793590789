import { parseISO } from 'date-fns'
import pMap from 'p-map'
import { getFile, getFileSha256 } from './accessRepositoryData'
import { getNewFileTimeEntries } from '../hooks/useTimetracking'
import { formatShortISODate } from './dateHelpers'
import { formatTrackedTimes } from './formatTrackedTimes'

export const PURPOSES_MAP = {
  contracts: 'contracts',
  educations: 'educations',
  sicks: 'sicks',
  vacations: 'vacations'
}
export const PURPOSES = Object.keys(PURPOSES_MAP)

export const getUsersQuery = (pageCursor = '') => `
{
  users(
    after: "${pageCursor}"
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        username
        id
        name
        state
        bot
      }
    }
  }
}`

export const getAllUsers = async (requestGraphQlData, token, signal) => {
  let pageCursor = ''
  let isMoreDataAvailable = true
  const users = []
  try {
    while (isMoreDataAvailable) {
      const query = getUsersQuery(pageCursor)
      const response = await requestGraphQlData(query, signal)
      isMoreDataAvailable = false
      pageCursor = response.data.users.pageInfo.endCursor
      isMoreDataAvailable = response.data.users.pageInfo.hasNextPage
      const newUsers = response.data.users.edges.map(({ node }) => node)
      users.push(...newUsers)
    }
    return users
  } catch (error) {
    if (error instanceof DOMException && signal.aborted) {
      return
    }
    const errorMessage = `getAllUsers failed:, ${error?.message}`
    console.error(errorMessage)
    throw new Error(errorMessage)
  }
}

export const getFilteredUsers = async (requestGraphQlData, token, signal) => {
  const allUsers = await getAllUsers(requestGraphQlData, token, signal)
  if (!allUsers) {
    return allUsers
  }
  const formattedUsers = allUsers.reduce((acc, user) => {
    if (user.state !== 'active' || user.bot || user.username.length > 2) {
      return acc
    }
    const userId = user.id.split('/').pop()
    acc.push({
      id: userId,
      username: user.username,
      name: user.name
    })
    return acc
  }, [])
  if (formattedUsers.length === 0) {
    throw new Error('getFilteredUserIds failed - No active users were found')
  }
  return formattedUsers
}

const getCachedUserPurposeTimes = (id, purpose, cachedUserTimes) => {
  if (!cachedUserTimes || cachedUserTimes.length === 0) {
    return {}
  }
  const cachedUser = cachedUserTimes.find(user => user.id === id)
  if (!cachedUser) {
    return {}
  }
  const cachedPurpose = cachedUser.purposeTimes.find(time => time.purpose === purpose)
  if (!cachedPurpose) {
    return {}
  }

  return cachedPurpose
}

const getUserData = async ({ token, id, purpose, cachedUsersTimes }) => {
  const cachedUserPurposeTime = getCachedUserPurposeTimes(id, purpose, cachedUsersTimes)
  const cachedUserPurposeTimeExists = cachedUserPurposeTime.sha256 !== undefined
  if (cachedUserPurposeTimeExists) {
    const { sha256: sha256FromRepo } = await getFileSha256(token, id, purpose)
    const fileShaMatches = sha256FromRepo === cachedUserPurposeTime.sha256
    if (fileShaMatches) {
      return cachedUserPurposeTime
    }
  }
  const { ok, body, sha256 } = await getFile(token, id, purpose)
  if (!ok) {
    throw new Error('getUserData failed | couldn\'t get file')
  }
  return { body, sha256, purpose }
}

export const getUsersTimes = async ({ token, users, cachedUsersTimes, incrementPartialProgress }) => {
  try {
    const usersTimes = await pMap(users, async ({ id, username, name }) => {
      const purposeTimes = await pMap(PURPOSES, async (purpose) => {
        if (incrementPartialProgress) {
          await incrementPartialProgress()
        }
        try {
          const userData = await getUserData({ token, id, purpose, cachedUsersTimes })
          return userData
        } catch (error) {
          const isPurposeRequired = purpose === 'contracts'
          if (isPurposeRequired) {
            throw new Error(`Failed to get ${name}'s required "${purpose}" data | userID: ${id}`)
          }
          console.log(`Failed to get ${name}'s "${purpose}" data, possibly doesn't exist | userID: ${id}`)
        }
      }, { concurrency: 4 })
      const purposeTimesWithoutEmptyData = purposeTimes.filter((data) => data)

      // get start stop events
      try {
        const userTimerData = await getNewFileTimeEntries({ username }, token)
        purposeTimesWithoutEmptyData?.push({ body: { userData: userTimerData }, purpose: 'timetracking' })
      } catch (error) {
        console.log(`Failed to get ${name}'s "timetracking" data, possibly doesn't exist | userID: ${id}`)
      }

      return { id, username, name, purposeTimes: purposeTimesWithoutEmptyData }
    }, { concurrency: 4 })
    return usersTimes
  } catch (error) {
    console.error(`getUsersTimes failed ${error}`)
    throw new Error(error)
  }
}

export const getAdminTableData = (usersTimes) => {
  const tableData = usersTimes.reduce((acc, user) => {
    const { purposeTimes, name, username, id: userId } = user
    purposeTimes.forEach(({ body: { userData }, sha256, purpose }) => {
      userData.forEach((time) => {
        const { createdAt, hours, id, startDate, endDate, updatedAt, hoursVacation } = time
        acc[purpose]?.push({
          id,
          user: {
            id: userId,
            username,
            name
          },
          hours,
          hoursVacation,
          createdAt: parseISO(createdAt),
          startDate: parseISO(startDate),
          endDate: parseISO(endDate),
          updatedAt: parseISO(updatedAt),
          key: id,
          sha256,
          purpose
        })
      })
    })
    return acc
  }, { contracts: [], educations: [], sicks: [], vacations: [] })
  return tableData
}

export const usersTrackedTimesQuery = ({ startDate, endDate, cursor = '' }) => `
{
  timelogs (
    startDate: "${formatShortISODate(startDate)}",
    endDate: "${formatShortISODate(endDate)}",
    after: "${cursor}",
    first: 100
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      user {
        username
      }
      id
      spentAt
      timeSpent
      issue {
        title
        projectId
        webPath
      }
      mergeRequest {
        projectId
        webUrl
        title
      }
    }
  }
}`

export const getUsersTrackedTimes = async (requestGraphQlData, signal, { startDate, endDate }) => {
  let pageCursor = ''
  let isMoreDataAvailable = true
  let trackedTimeNodes = []
  try {
    while (isMoreDataAvailable) {
      const query = usersTrackedTimesQuery({ startDate, endDate, cursor: pageCursor })

      const response = await requestGraphQlData(query, signal)

      pageCursor = response.data.timelogs.pageInfo.endCursor
      isMoreDataAvailable = response.data.timelogs.pageInfo.hasNextPage

      trackedTimeNodes = [...trackedTimeNodes, ...response.data.timelogs.nodes]
    }

    const trackedTimesPerUser = trackedTimeNodes.reduce((acc, node) => {
      if (!acc[node.user.username]) {
        acc[node.user.username] = []
      }
      acc[node.user.username].push(node)
      return acc
    }, {})

    for (const key in trackedTimesPerUser) {
      if (Object.hasOwnProperty.call(trackedTimesPerUser, key)) {
        trackedTimesPerUser[key] = formatTrackedTimes({}, trackedTimesPerUser[key])
      }
    }
    return trackedTimesPerUser
  } catch (error) {
    if (error instanceof DOMException && signal.aborted) {
      console.log('Error')
      return
    }
    const errorMessage = `getUsersTrackedTimes failed:, ${error?.message}`
    console.error(errorMessage)
    throw new Error(errorMessage)
  }
}
