// Timetracking calculation helper functions

/**
 * calculate the sum of hours in a list of timetracking entries
 * example entry { type: 'spent', hours: 1.5 }
 * @param {*} entries
 * @returns object with sum of tracked and spent hours in list of entries, as well as the total
 */
export const getHoursInEntries = (entries = []) => {
  return entries.reduce((acc, entry) => {
    if (entry.hours) {
      if (entry.type && Object.hasOwn(acc, entry.type)) {
        acc[entry.type] += entry.hours
      }
      acc.total += entry.hours
    }
    return acc
  }, { tracked: 0, spent: 0, total: 0 })
}
