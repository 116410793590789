export const config = {
  basePath: process.env.REACT_APP_BASE_PATH ?? '',
  auth: {
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
    authorizationEndpoint: process.env.REACT_APP_OAUTH_AUTH_ENDPOINT,
    tokenEndpoint: process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT,
    scope: process.env.REACT_APP_OAUTH_SCOPE,
    redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
    logoutRedirect: process.env.REACT_APP_OAUTH_LOGOUT_URI,
    revokeEndpoint: process.env.REACT_APP_OAUTH_REVOKE_ENDPOINT
  }
}

export default config
