import { Table, Button, Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { capitalizeFirstLetter } from '../../lib/util'
import { timeSpentColumn, LinkButton } from './Table'

const { REACT_APP_GITLAB_URL: gitlabUrl } = process.env

const ExpandedTable = ({ record, groupBy }) => {
  const columns = [
    {
      title: capitalizeFirstLetter(groupBy),
      dataIndex: groupBy === 'project' ? 'key' : 'title',
      key: 'title',
      render: (_, { key, title }) => {
        if (key === 'unassigned') {
          return title
        }
        let url = `${gitlabUrl}/p/${record.key}/${key}`
        if (groupBy === 'milestone' || groupBy === 'epic') {
          url = `${gitlabUrl}/groups/p/${record.key}/-/${groupBy}s/${key}`
        }
        return (
          <LinkButton href={url} type='link' target='_blank'>
            {key.startsWith('MR-') ? 'MR: ' : ''}{groupBy === 'project' ? key : title}
          </LinkButton>
        )
      },
      sorter: (a, b) => groupBy === 'project' ? a?.key?.localeCompare(b?.key) : a?.title?.localeCompare(b?.title),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            placeholder={`Search ${capitalizeFirstLetter(groupBy)}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{
              marginBottom: 8,
              display: 'block'
            }}
          />
          <Space>
            <Button
              type='primary'
              onClick={confirm}
              icon={<SearchOutlined />}
              size='small'
              style={{
                width: 90
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => { clearFilters && clearFilters(); confirm() }}
              size='small'
              style={{
                width: 90
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => record[groupBy === 'project' ? 'key' : 'title'].toString().toLowerCase().includes(value.toLowerCase())
    },
    timeSpentColumn
  ]
  let dataSource = []
  if (groupBy === 'project' && record.projects) {
    dataSource = Object.keys(record.projects).map((project) => {
      return {
        key: project,
        ...record.projects[project]
      }
    })
  } else if (groupBy === 'milestone' && record.milestones) {
    dataSource = Object.keys(record.milestones).map((milestone) => {
      return {
        key: milestone,
        ...record.milestones[milestone]
      }
    })
  } else if (groupBy === 'epic' && record.epics) {
    dataSource = Object.keys(record.epics).map((epic) => {
      return {
        key: epic,
        ...record.epics[epic]
      }
    })
  }
  if (record.unassignableTimeSpent) {
    dataSource.push({
      key: 'unassigned',
      title: 'Unassigned Times',
      timeSpent: record.unassignableTimeSpent,
      issues: record.unassignableTimelogs && record.unassignableTimelogs.reduce((acc, timelog) => {
        if (!timelog.issue && !timelog.mergeRequest) {
          return acc
        }
        if (timelog.mergeRequest) {
          if (!acc[timelog.mergeRequest.id]) {
            acc['MR-' + timelog.mergeRequest.id] = {
              title: 'MR: ' + timelog.mergeRequest.title,
              timeSpent: timelog.timeSpent,
              timelogs: [timelog],
              url: timelog.mergeRequest.webUrl
            }
          } else {
            acc['MR-' + timelog.mergeRequest.id].timeSpent += timelog.timeSpent
            acc['MR-' + timelog.mergeRequest.id].timelogs.push(timelog)
          }
        } else if (timelog.issue) {
          if (!acc[timelog.issue.id]) {
            acc[timelog.issue.id] = {
              title: timelog.issue.title,
              timeSpent: timelog.timeSpent,
              timelogs: [timelog],
              url: timelog.issue.webUrl
            }
          } else {
            acc[timelog.issue.id].timeSpent += timelog.timeSpent
            acc[timelog.issue.id].timelogs.push(timelog)
          }
        }
        return acc
      }, {})
    })
  }
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (record) => {
          const columns = [
            {
              title: 'Issue',
              dataIndex: 'title',
              key: 'title',
              render: (_, { title, url, body }) => {
                if (url) {
                  return (
                    <a href={url} target='_blank' rel='noreferrer'>{title}</a>
                  )
                }
                return <div title={body}>{title}</div>
              }
            },
            timeSpentColumn
          ]
          const dataSource = record.issues && Object.keys(record.issues).map((issue) => {
            return {
              key: issue,
              ...record.issues[issue],
              children: record.issues[issue] && record.issues[issue].timelogs && record.issues[issue].timelogs.map((timelog) => {
                const maxLength = 40
                let body = timelog.note?.body.length > maxLength ? timelog.note?.body.substring(0, maxLength) + '...' : timelog.note?.body || ''
                body = body.replace('- ', '')
                return {
                  key: timelog.id,
                  ...timelog,
                  title: timelog.user.username + ' - ' + timelog.spentAt.replace('T', ' ').replace('Z', '') + (body ? ' - ' + body : ''),
                  body: timelog.note?.body
                }
              })
            }
          })

          return (
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              expandable={false}
            />
          )
        }
      }}
    />
  )
}

export default ExpandedTable
