import { isWithinInterval, parseISO } from 'date-fns'
import { getWorkingDays } from './dateHelpers'
import { getUsersTimes, PURPOSES } from './adminData'

const getUserIdQuery = `
{
  currentUser {
    id
    name
    username
  }
}`

export const getCurrentUser = async (requestGraphQlData) => {
  try {
    const response = await requestGraphQlData(getUserIdQuery)
    const { id: gid, name, username } = response.data.currentUser
    const id = gid.split('/').pop()
    return { id, name, username }
  } catch (error) {
    console.error(error)
    throw new Error('Could not get user id')
  }
}

export const getUserBookedTimes = async (requestGraphQlData, token, displayError) => {
  try {
    const currentUser = await getCurrentUser(requestGraphQlData)
    // TODO: pass cachedUserTimes to getUsersTimes
    const [bookedTimes] = await getUsersTimes({ token, users: [currentUser] })
    const formattedBookedTimes = PURPOSES.map((purpose, index) => {
      const time = bookedTimes.purposeTimes.find((time) => purpose === time.purpose)
      return time?.body?.userData || []
    })
    return formattedBookedTimes
  } catch (error) {
    console.error('getUserBookedTimes failed', error)
    displayError(error?.message)
  }
}

const getContractPeriod = (contractPeriod) => {
  const currentContractPeriod = contractPeriod.find(({ startDate, endDate }) => {
    return isWithinInterval(new Date(), { start: parseISO(startDate), end: parseISO(endDate) })
  })
  const { startDate, endDate, hours } = currentContractPeriod
  const businessDays = getWorkingDays({ startDate, endDate }).length - 1 // hardcoded fix as in 2024 xmas and new year are 1/2 work days
  return { businessDays, startDate, endDate, hours }
}

export const getUserContract = (contractPeriods) => {
  const { businessDays, startDate, endDate, hours } = getContractPeriod(contractPeriods)
  const requiredDailyAverageHours = hours / businessDays
  const thresholdHours = {
    red: Math.floor(hours * 0.04),
    yellow: Math.floor(hours * 0.02)
  }
  const formattedContract = {
    startDate,
    endDate,
    businessDays,
    requiredHours: hours,
    requiredDailyAverageHours,
    thresholdHours
  }
  return formattedContract
}
