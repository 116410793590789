import React from 'react'
import styled from 'styled-components'
import DiagramContainer from './DiagramContainer'
import CurrentBalance from './CurrentBalance'
import PendingLoggedToday from './PendingLoggedToday'
import WorktimeBar from './WorktimeBar'
import { Col, Row } from 'antd'
import TrackedTimesTable from './TrackedTimesTable'

const StyledBox = styled.div`
  background-color: #FFF;
  min-height: 350px;
  border-radius: 5px;
  margin: 16px;
  padding: 3px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`

const StyledTableContainer = styled.div`
  background-color: #FFF;
  border-radius: 5px;
  margin: 32px 16px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`
const TimelogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledTimelogs = styled.div`
  min-width: 400px;
  background-color: #f3f3f3;
  width: 100%;
  @media (min-width: 1200px) {
    padding-inline: 1vw;
  }
`

const Timelogs = (props) => {
  return (
    <TimelogsContainer {...props}>
      <StyledTimelogs>
        <Row justify='space-around' align='middle'>
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} lg={8}>
            <StyledBox>
              <CurrentBalance />
            </StyledBox>
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
            <StyledBox>
              <WorktimeBar />
            </StyledBox>
          </Col>
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} lg={8}>
            <StyledBox>
              <PendingLoggedToday />
            </StyledBox>
          </Col>
        </Row>
        <StyledBox>
          <DiagramContainer />
        </StyledBox>
        <StyledTableContainer>
          <TrackedTimesTable />
        </StyledTableContainer>
      </StyledTimelogs>
    </TimelogsContainer>
  )
}

export default Timelogs
