import { useEffect, useState } from 'react'
import { Column } from '@ant-design/plots'
import { useTimelog } from '../../../hooks/useTimelog'
import { useTimetracking } from '../../../hooks/useTimetracking'
import { getWeekDaysWithinRangeFromIso, unixTimestampToIsoDate, humanizeTime } from '../../../lib/dateHelpers'
import { getAccProjectTime, getProjectTitle } from '../../../lib/formatChartData'
import { each, groupBy } from '@antv/util'
import { scrollToDay } from '../../../lib/util'
import { getHoursInEntries } from '../../../lib/timetracking'

const BarChart = (props) => {
  const {
    selectedRange,
    barChartData,
    setHighlightedDay,
    setIsLoadingBarChart
  } = useTimelog()

  const {
    timeTrackedByDay
  } = useTimetracking()

  const [chartData, setChartData] = useState([])

  useEffect(() => {
    const formattedChartData = barChartData.reduce((acc, item) => {
      const existingProjectIndex = acc.findIndex(entry => entry.projectId === item.projectId && entry.dayTimestamp === item.dayTimestamp)
      if (existingProjectIndex > -1) {
        const sum = acc[existingProjectIndex].hours + item.hours
        acc[existingProjectIndex] = {
          ...acc[existingProjectIndex],
          hours: sum,
          timeSpent: acc[existingProjectIndex].timeSpent + item.timeSpent
        }
      } else {
        acc.push({ ...item, type: 'spent' })
      }
      return acc
    }, [])
    if (timeTrackedByDay) {
      const timeTrackedInRange = Object.values(timeTrackedByDay).filter(({ datetime }) => datetime >= selectedRange.start && datetime <= selectedRange.end)
      formattedChartData.push(...timeTrackedInRange)
    }
    setChartData(formattedChartData)
    setIsLoadingBarChart(false)
  }, [barChartData, timeTrackedByDay]) // eslint-disable-line

  const handleChartClick = (dayTimestamp) => {
    const isoDate = unixTimestampToIsoDate(dayTimestamp)
    setHighlightedDay(isoDate)
    scrollToDay(isoDate)
  }

  const annotations = []
  each(groupBy(chartData, 'dayTimestamp'), (day, dayTimestamp) => {
    const { spent: dayHours, tracked } = getHoursInEntries(day)
    if (dayHours === 0) {
      return
    }
    annotations.push({
      type: 'text',
      position: [dayTimestamp, tracked > dayHours ? tracked : dayHours],
      content: `${humanizeTime(dayHours * 3600)}`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'rgba(0,0,0,0.85)'
      },
      offsetY: -10
    })
  })

  const config = {
    data: chartData,
    height: 300,
    isGroup: true,
    isStack: true,
    xField: 'dayTimestamp',
    appendPadding: [20, 0, 0, 0],
    legend: {
      itemValue: {
        formatter: (text) => {
          const accumulatedTimes = getAccProjectTime(chartData, text)
          const hours = humanizeTime(accumulatedTimes)
          return hours
        },
        style: {
          opacity: 0.65
        }
      },
      itemName: {
        formatter: (text) => getProjectTitle(chartData, text)
      },
      reversed: true
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
        formatter: getWeekDaysWithinRangeFromIso
      }
    },
    yField: 'hours',
    seriesField: 'projectShortPath',
    groupField: 'type',
    yAxis: {
      tickInterval: 2
    },
    label: false,
    tooltip: {
      title: 'isoDate',
      formatter: ({ projectShortPath, hours }) => (
        {
          name: projectShortPath || 'No Data',
          value: hours && humanizeTime(hours * 3600)
        }
      )
    },
    annotations,
    interactions: [
      {
        type: 'active-region',
        enable: false
      }
    ],
    connectedArea: {
      style: (oldStyle) => {
        return {
          fill: 'rgba(0,0,0,0.25)',
          stroke: oldStyle.fill,
          lineWidth: 0.5
        }
      }
    },
    onReady: (plot) => {
      plot.on(
        'element:click',
        ({ data: { data: { dayTimestamp } } }) => {
          handleChartClick(dayTimestamp)
        }
      )
    }
  }

  return (
    <Column {...config} {...props} />
  )
}

export default BarChart
