import React, { useState } from 'react'
import { Button, Col, InputNumber, Modal, Popconfirm, Row, Space, Typography } from 'antd'
import DatePicker from '../../../common/DatePicker'
import PropTypes from 'prop-types'
import { formatShortISODate } from '../../../../lib/dateHelpers'
import { UserSelection } from './UserSelection'
import { useAdmin } from '../../../../hooks/useAdmin'
const { RangePicker } = DatePicker
const { Text } = Typography

const VIEWS = {
  VIEW: {
    name: 'VIEW',
    title: 'View Entry'
  },
  EDIT: {
    name: 'EDIT',
    title: 'Edit Entry'
  },
  CREATE: {
    name: 'CREATE',
    title: 'Create Entry'
  }
}

export const EntryModal = ({
  users,
  onSubmit,
  onClose,
  onDelete,
  loading,
  isVisible,
  entryData,
  ...restProps
}) => {
  const [dateRange, setDateRange] = useState({ start: entryData?.startDate, end: entryData?.endDate })
  const [selectedUser, setSelectedUser] = useState(entryData?.user)
  const [hours, setHours] = useState(Math.floor(entryData?.hours))
  const [hoursVacation, setHoursVacation] = useState(Math.floor(entryData?.hoursVacation))
  const [minutes, setMinutes] = useState((entryData?.hours * 60) % 60)
  const [minutesVacation, setMinutesVacation] = useState((entryData?.hoursVacation * 60) % 60)
  const [view, setView] = useState(entryData ? VIEWS.VIEW.name : VIEWS.CREATE.name)
  const [isDisabled, setIsDisabled] = useState(view === VIEWS.VIEW.name)
  const { currentMenuTitle, selectedMenuPoint } = useAdmin()

  const onEdit = () => {
    setView(VIEWS.EDIT.name)
    setIsDisabled(false)
  }

  const onEditCancel = () => {
    setIsDisabled(true)
    setView(VIEWS.VIEW.name)
    setHours(entryData?.hours)
    setHoursVacation(entryData?.hoursVacation)
    setDateRange({ start: entryData?.startDate, end: entryData?.endDate })
    setSelectedUser(entryData?.user)
  }

  const handleDelete = async () => {
    if (onDelete) {
      await onDelete(entryData, true)
    }
    onClose()
  }

  const handleSubmit = async () => {
    const minutesInHours = (Number(minutes || 0) / 60)
    const totalHours = Number(hours || 0) + minutesInHours
    const minutesInHoursVacation = (Number(minutesVacation || 0) / 60)
    const totalHoursVacation = Number(hoursVacation || 0) + minutesInHoursVacation
    await onSubmit({
      ...entryData,
      user: selectedUser,
      hours: totalHours,
      hoursVacation: totalHoursVacation,
      startDate: dateRange.start,
      endDate: dateRange.end,
      purpose: selectedMenuPoint
    })
  }

  const handleClosing = () => {
    onEditCancel()
    onClose()
  }

  const canSubmit = !!(dateRange?.start && dateRange?.end && selectedUser && (hours || minutes)) || false

  const CloseButton = (props) => (
    <Button key='close' onClick={handleClosing} {...props}>
      Close
    </Button>
  )

  const FOOTERS = {
    VIEW: [
      <Button key='edit' type='primary' loading={loading && loading !== 'deleting'} onClick={onEdit}>
        Edit
      </Button>,
      <CloseButton key='close' />
    ],
    EDIT: [
      <Popconfirm
        key='confirm-delete'
        onConfirm={handleDelete}
        title='Are you sure you want to delete this entry?'
      >
        <Button key='delete' type='danger'>
          Delete
        </Button>
      </Popconfirm>,
      <Popconfirm
        key='confirm-save'
        onConfirm={handleSubmit}
        title='Are you sure you want to save this entry?'
      >
        <Button key='save' type='primary' loading={false} disabled={!canSubmit}>
          Save Edit
        </Button>
      </Popconfirm>,

      <Button key='cancel' onClick={onEditCancel}>
        Cancel
      </Button>
    ],
    CREATE: [
      <Button key='create' type='primary' loading={loading} onClick={handleSubmit} disabled={!canSubmit}>
        Create
      </Button>,
      <CloseButton key='close' />
    ]
  }

  const handleUserSelect = (selectedUser) => {
    setSelectedUser(selectedUser)
  }

  const userSelectionProps = {
    isDisabled,
    users,
    selectedUser,
    onUserSelect: handleUserSelect
  }

  const handleRangePicker = (pickedRange) => {
    if (!pickedRange) {
      setDateRange({ start: null, end: null })
      return
    }
    const [start, end] = pickedRange
    setDateRange({ start, end })
  }

  return (
    <Modal
      title={`${currentMenuTitle} - ${VIEWS[view].title}`}
      visible={isVisible}
      onCancel={handleClosing}
      width={500}
      footer={FOOTERS[view]}
      {...restProps}
    >
      <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
        <Row justify='space-between'>
          {entryData?.createdAt && (
            <Text type='secondary'>Created at: {formatShortISODate(entryData.createdAt)}</Text>
          )}
          {entryData?.updatedAt && (
            <Text type='secondary'>Updated at: {formatShortISODate(entryData.updatedAt)}</Text>
          )}
        </Row>
        <Row>
          <Col span={20}>
            <UserSelection
              {...userSelectionProps}
              userPropertyKey='name'
            />
          </Col>
        </Row>
        <Row />
        <Row />
        <Row />
        <Row>
          <RangePicker
            disabled={isDisabled}
            onChange={(range) => handleRangePicker(range)}
            defaultValue={dateRange ? [dateRange.start, dateRange.end] : null}
            value={dateRange ? [dateRange.start, dateRange.end] : null}
          />
        </Row>
        <Row />
        Work Hours
        <Row gutter={[16]}>
          <Col span={12}>
            <InputNumber
              addonBefore='Hours'
              defaultValue={hours}
              disabled={isDisabled}
              onChange={(hours) => setHours(hours)}
              value={hours || null}
              min={0}
            />
          </Col>
          <Col span={12}>
            <InputNumber
              addonBefore='Minutes'
              defaultValue={minutes}
              disabled={isDisabled}
              onChange={(minutes) => setMinutes(minutes)}
              value={minutes || null}
              min={0}
            />
          </Col>
        </Row>
        Vacation Hours
        <Row gutter={[16]}>
          <Col span={12}>
            <InputNumber
              addonBefore='Hours'
              defaultValue={hoursVacation}
              disabled={isDisabled}
              onChange={(hours) => setHoursVacation(hours)}
              value={hoursVacation || null}
              min={0}
            />
          </Col>
          <Col span={12}>
            <InputNumber
              addonBefore='Minutes'
              defaultValue={minutesVacation}
              disabled={isDisabled}
              onChange={(minutes) => setMinutesVacation(minutes)}
              value={minutesVacation || null}
              min={0}
            />
          </Col>
        </Row>
        <Row />
      </Space>
    </Modal>
  )
}

EntryModal.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: (props) => {
    if (props.entryData && (typeof props.onDelete !== 'function')) {
      return new Error('Prop onDelete function is required when entryData is present')
    }
  },
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  entryData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    }).isRequired,
    hours: PropTypes.number.isRequired,
    createdAt: PropTypes.instanceOf(Date).isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    purpose: PropTypes.string.isRequired
  })
}
